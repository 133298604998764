import React from 'react';

import { styled } from '@mui/material/styles';

import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import { EntityCardExpander } from 'client/app/apps/protocols/EntityCardExpander';
import { useProtocolListActionContext } from 'client/app/apps/protocols/ProtocolListActionProvider';
import { RecentProtocolInstancesFetcher } from 'client/app/apps/protocols/RecentProtocolInstanceList';
import { HasPermission } from 'client/app/components/Permissions';
import { Protocol, ProtocolsQuery } from 'client/app/gql';
import { EntityCard } from 'common/ui/components/EntityCard';
import EntityCardMenu from 'common/ui/components/EntityCardMenu';
import { ProtocolIcon } from 'common/ui/icons';

type Props = {
  data: ProtocolsQuery;
  currentUserId: string | undefined;
};

const ProtocolsListDataComponent = ({ data, currentUserId }: Props) => {
  const protocols = data.protocols.items || [];
  const {
    handleOpenDialog,
    handleEditProtocol,
    handleDuplicateProtocol,
    handleDeleteProtocolWithConfirmation,
    handleOpenUpdateProtocolDialog,
  } = useProtocolListActionContext();

  return (
    <List>
      {protocols.map(protocol => {
        return (
          <EntityCardExpander
            key={protocol.id}
            whenExpanded={<RecentProtocolInstancesFetcher protocolId={protocol.id} />}
          >
            <StyledEntityCard
              icon={<ProtocolIcon />}
              nameColumn={{ value: protocol.shortDescription, label: protocol.name }}
              authorColumn={{ value: protocol.createdBy.displayName, label: 'Author' }}
              interaction={{
                onClick: () => handleOpenDialog(protocol as Protocol),
              }}
              rightSlot={
                <HasPermission
                  permission="delete:othersProtocol"
                  renderItem={hasPermission => {
                    const isOwner = currentUserId === protocol.createdBy.id;
                    const canDelete = hasPermission || isOwner;
                    return (
                      <EntityCardMenu
                        menu={[
                          ...(protocol.isPublished === false
                            ? [
                                {
                                  label: 'Edit',
                                  onClick: () =>
                                    handleEditProtocol(protocol.id, protocol.version),
                                },
                              ]
                            : isOwner
                            ? [
                                {
                                  label: 'Update',
                                  onClick: () =>
                                    handleOpenUpdateProtocolDialog(
                                      protocol.id,
                                      protocol.version,
                                    ),
                                },
                              ]
                            : []),
                          {
                            label: 'Duplicate',
                            onClick: () =>
                              handleDuplicateProtocol(protocol.id, protocol.version),
                          },
                          {
                            label: 'Delete',
                            disabled: !canDelete,
                            tooltip: canDelete
                              ? ''
                              : "Only admins may delete other user's Protocols",
                            onClick: () =>
                              handleDeleteProtocolWithConfirmation(protocol.id),
                          },
                        ]}
                      />
                    );
                  }}
                />
              }
            />
          </EntityCardExpander>
        );
      })}
    </List>
  );
};

const List = styled('div')(({ theme }) => ({
  ...experimentsStyles(theme).list,
}));

const StyledEntityCard = styled(EntityCard)(({ theme }) => ({
  border: 'none',
  '.EntityCardContent-nameColumn-label': {
    ...theme.typography.subtitle2,
  },
  '.EntityCardContent-nameColumn-value': {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
}));

export default ProtocolsListDataComponent;
