import React from 'react';

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

import { QUERY_PROTOCOLS } from 'client/app/api/gql/queries';
import { ProtocolListActionProvider } from 'client/app/apps/protocols/ProtocolListActionProvider';
import DataComponent from 'client/app/apps/protocols/ProtocolsListDataComponent';
import NoDataComponent from 'client/app/apps/protocols/ProtocolsListNoDataComponent';
import usePagination from 'client/app/hooks/usePagination';
import { PageInfo } from 'common/server/graphql/pagination';
import { EntityCardSkeletonList } from 'common/ui/components/EntityCard';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';

type QueryVariables = {
  isLatest?: boolean;
  isDeleted?: boolean;
  isPublic?: boolean;
  isPublished?: boolean;
  userId?: string;
};

type Props = {
  hidden: boolean;
  currentUserId: string | undefined;
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
  searchQuery?: string;
  tagQuery?: string;
  queryVariables: QueryVariables;
};

const ProtocolsList = ({
  hidden,
  currentUserId,
  scrollableContainerRef,
  searchQuery,
  tagQuery,
  queryVariables,
}: Props) => {
  const variables = {
    search: searchQuery ?? undefined,
    tagName: tagQuery ?? undefined,
    ...queryVariables,
  };

  const protocolsQuery = useQuery(QUERY_PROTOCOLS, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const pageInfo = protocolsQuery.data?.protocols.pageInfo as PageInfo | undefined;

  const hasNextPage = usePagination({
    entity: 'protocols',
    pageInfo,
    fetchMore: protocolsQuery.fetchMore,
    dependencies: [],
    scrollableRef: scrollableContainerRef,
    isInitialLoading: protocolsQuery.loading,
    variables: {},
  });

  return (
    <Box hidden={hidden}>
      <ProtocolListActionProvider>
        <RenderQuery
          query={protocolsQuery}
          renderData={DataComponent}
          renderNoData={NoDataComponent}
          additionalDataProps={{
            currentUserId,
          }}
          loadingComponent={EntityCardSkeletonList}
          additionalNoDataProps={{ searchQuery }}
          emptyCondition={data => data.protocols.items.length === 0}
        />
      </ProtocolListActionProvider>
      {hasNextPage && (
        <LoadingContainer>
          <CircularProgress size={24} />
        </LoadingContainer>
      )}
    </Box>
  );
};

const LoadingContainer = styled('div')(() => ({
  width: 'fit-content',
  margin: '0 auto',
}));

export default ProtocolsList;
