import { AdditionalEditorProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { createParameterEditorConfigurationSpec } from 'common/elementConfiguration/createConfigurationSpec';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { Markdown } from 'common/lib/markdown';

export function getAdditionalEditorPropsForEditorType(
  editorType: EditorType,
  anthaType?: string,
): AdditionalEditorProps | null | undefined {
  if (
    anthaType &&
    (editorType === EditorType.ARRAY ||
      editorType === EditorType.MAP ||
      editorType === EditorType.PLATE_CONTENTS)
  ) {
    // The logic for recursively generating compound additionalEditorProps is
    // already encapsulated in the ParameterEditorConfigurationSpec creation
    // process without much additional overhead, so we just make one and
    // extract the additionalEditorProps from it.
    const { additionalProps } = createParameterEditorConfigurationSpec(
      anthaType,
      undefined,
      editorType,
    );
    return additionalProps;
  }

  return editorTypeInfo[editorType]?.defaultAdditionalEditorProps;
}

/**
 * Returns an object containing properties of the given editorType if they are
 * defined, and undefined if they are not.
 * */
export const getEditorTypeProperties = (
  editorType: EditorType,
): EditorTypeProperties | undefined => {
  const entry = editorTypeInfo[editorType];
  if (entry) {
    return entry;
  }
  return undefined;
};

const editorTypeInfo: Record<EditorType, EditorTypeInfo> = {
  [EditorType.ARRAY]: {
    displayName: 'Array editor',
    canSetPlaceholder: false,
    defaultAdditionalEditorProps: {
      editor: EditorType.ARRAY,
    },
  },
  [EditorType.AUTOCOMPLETE]: {
    displayName: 'Autocomplete text field',
    canSetPlaceholder: true,
    defaultAdditionalEditorProps: {
      editor: EditorType.AUTOCOMPLETE,
      canAcceptCustomValues: true,
      useDynamicOptions: true,
      staticOptions: [],
      provideDefaultKey: false,
    },
  },
  [EditorType.CONNECTION_ONLY]: {
    displayName: 'Connection only',
    canSetPlaceholder: false,
  },
  [EditorType.CHECKBOX]: {
    displayName: 'Checkbox',
    canSetPlaceholder: false,
  },
  [EditorType.CHERRY_PICK]: {
    displayName: 'Cherry Picker editor',
    canSetPlaceholder: false,
  },
  [EditorType.DECK_POSITIONS]: {
    displayName: 'Deck Positions',
    canSetPlaceholder: false,
  },
  [EditorType.DNA]: {
    displayName: 'DNA dropdown',
    canSetPlaceholder: false,
  },
  [EditorType.DOE_MERGE_OPTION]: {
    displayName: 'DOE merge option dropdown',
    canSetPlaceholder: false,
  },
  [EditorType.DROPDOWN]: {
    displayName: 'Dropdown',
    canSetPlaceholder: true,
    defaultAdditionalEditorProps: {
      editor: EditorType.DROPDOWN,
      useDynamicOptions: false,
      options: [],
    },
  },
  [EditorType.FILE]: {
    displayName: 'File selector',
    canSetPlaceholder: false,
    defaultAdditionalEditorProps: {
      editor: EditorType.FILE,
    },
  },
  [EditorType.FILE_SERIES]: {
    displayName: 'File series selector (only for use with Upload Raman Spectra!)',
    canSetPlaceholder: false,
  },
  [EditorType.FILTER_PLATE_PROTOCOL_DESIGN]: {
    displayName: 'Filter plate protocol design',
    canSetPlaceholder: false,
  },
  [EditorType.FLOAT]: {
    displayName: 'Text field (float validation)',
    canSetPlaceholder: true,
  },
  [EditorType.INT]: {
    displayName: 'Text field (integer validation)',
    canSetPlaceholder: true,
  },
  [EditorType.LIQUID]: {
    displayName: 'Liquid dropdown',
    canSetPlaceholder: true,
  },
  [EditorType.MAP]: {
    displayName: 'Map editor',
    canSetPlaceholder: false,
  },
  [EditorType.MEASUREMENT]: {
    displayName: 'Measurement editor',
    canSetPlaceholder: true,
    defaultAdditionalEditorProps: {
      editor: EditorType.MEASUREMENT,
      units: [],
      defaultUnit: undefined,
    },
  },
  [EditorType.MULTI_FILE]: {
    displayName: 'Multiple file selector',
    canSetPlaceholder: false,
  },
  [EditorType.PLATE_READER_ABSORBANCE_PROTOCOL]: {
    displayName: 'Absorbance protocol selector',
    canSetPlaceholder: false,
  },
  [EditorType.PLATE_READER_ABSORBANCE_SPECTRA_PROTOCOL]: {
    displayName: 'Absorbance Spectra protocol selector',
    canSetPlaceholder: false,
  },
  [EditorType.PLATE_READER_FLUORESCENCE_PROTOCOL]: {
    displayName: 'Fluorescence protocol selector',
    canSetPlaceholder: false,
  },
  [EditorType.PLATE_READER_LUMINESCENCE_PROTOCOL]: {
    displayName: 'Luminescence protocol selector',
    canSetPlaceholder: true,
  },
  [EditorType.PLATE_WASHER_PROTOCOL]: {
    displayName: 'Plate washer protocol selector',
    canSetPlaceholder: true,
  },
  [EditorType.PLATE_READER_SHAKING_TYPE]: {
    displayName: 'Plate reader shaking type dropdown',
    canSetPlaceholder: false,
  },
  [EditorType.PLATE]: {
    displayName: 'Select a plate type or existing plate',
    canSetPlaceholder: false,
  },
  [EditorType.PLATE_CONTENTS]: {
    displayName: 'Deprecated. Plate contents editor',
    canSetPlaceholder: false,
  },
  [EditorType.PLATE_LAYOUT]: {
    displayName: 'Plate layout editor',
    canSetPlaceholder: false,
  },
  [EditorType.PLATE_LAYOUT_LAYERS]: {
    displayName: 'Plate layout by layer editor',
    canSetPlaceholder: false,
  },
  [EditorType.PLATE_DESCRIPTIONS]: {
    displayName: 'Plate description of regions of a plate',
    canSetPlaceholder: false,
  },
  [EditorType.PLATE_CONTENTS_DESCRIPTIONS]: {
    displayName: 'Plate description of regions of a plate for creation of plates',
    canSetPlaceholder: false,
  },
  [EditorType.PLATE_ELISA_DESCRIPTIONS]: {
    displayName: 'Plate description of regions for ELISA protocols',
    canSetPlaceholder: false,
  },
  [EditorType.PLATE_TYPE]: {
    displayName: 'Select a plate type',
    canSetPlaceholder: false,
  },
  [EditorType.POLICY]: {
    displayName: 'Liquid Policy selector',
    canSetPlaceholder: false,
  },
  [EditorType.SIMULATION]: {
    displayName: 'Simulation selector',
    canSetPlaceholder: false,
  },
  [EditorType.SIMULATION_RESULTS]: {
    displayName: 'Simulation details selector',
    canSetPlaceholder: false,
  },
  [EditorType.STRING]: {
    displayName: 'Text field',
    canSetPlaceholder: true,
  },
  [EditorType.EXISTING_PLATE]: {
    displayName: 'Select an existing plate',
    canSetPlaceholder: false,
  },
  [EditorType.STRING_ARRAY]: {
    displayName: 'Multiline string array editor',
    canSetPlaceholder: true,
  },
  [EditorType.SPREADSHEET]: {
    displayName: 'Spreadsheet',
    canSetPlaceholder: false,
    defaultAdditionalEditorProps: {
      editor: EditorType.SPREADSHEET,
      sheets: [
        {
          name: 'Sheet1',
          canAddColumns: false,
          columns: [
            {
              name: 'Column1',
              anthaType: 'string',
              displayName: null,
              description: '' as Markdown,
              dataType: 'string',
              editor: {
                type: EditorType.STRING,
                additionalProps: null,
              },
              isFixed: false,
              dragToFillBehaviour: 'copy',
              hasTrailingGap: false,
            },
          ],
        },
      ],
    },
  },
  [EditorType.TIP_TYPE]: {
    displayName: 'Tip type dropdown',
    canSetPlaceholder: false,
    defaultAdditionalEditorProps: {
      editor: EditorType.TIP_TYPE,
      supportedTipTypes: [],
    },
  },
  [EditorType.TOGGLE]: {
    displayName: 'Toggle',
    canSetPlaceholder: false,
  },
  [EditorType.TOGGLE_BUTTONS]: {
    displayName: 'Toggle buttons',
    canSetPlaceholder: false,
    defaultAdditionalEditorProps: {
      editor: EditorType.TOGGLE_BUTTONS,
      options: [],
    },
  },
  [EditorType.WELL_COORDS]: {
    displayName: 'Well selector',
    canSetPlaceholder: false,
  },
  [EditorType.UNIT]: {
    displayName: 'Unit dropdown',
    canSetPlaceholder: false,
    defaultAdditionalEditorProps: {
      editor: EditorType.UNIT,
      units: [],
    },
  },
  [EditorType.ROBOCOLUMN_LAYOUT_MAP]: {
    displayName: 'RoboColumn layout editor',
    canSetPlaceholder: false,
  },
  [EditorType.CHROMATOGRAPHY_ACTIONS_MAP]: {
    displayName: 'Chromatography actions editor',
    canSetPlaceholder: false,
  },
  [EditorType.GRADIENT_CHROMATOGRAPHY_ACTIONS_MAP]: {
    displayName: 'Gradient chromatography actions editor',
    canSetPlaceholder: false,
  },
};

/*
 * The "meta" information about an editor type, used when constructing
 * layouts for defining parameter details in configurations in the admin tool.
 */
type EditorTypeProperties = {
  displayName: string;
  canSetPlaceholder: boolean;
};

/** All of the hard-coded information about an editor type. */
type EditorTypeInfo = EditorTypeProperties & {
  defaultAdditionalEditorProps?: AdditionalEditorProps;
};
