import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { StepParamConfigById } from 'client/app/apps/protocols/context/StepsProvider/stepsConfig';
import { CreateInputStepState } from 'client/app/apps/protocols/context/StepsProvider/stepState';
import ProtocolInstancePanel from 'client/app/apps/protocols/ProtocolInstancePanel/ProtocolInstancePanel';
import { ProtocolItem } from 'client/app/apps/protocols/ProtocolInstancePanel/ProtocolItem';
import { useElementInputs } from 'client/app/apps/protocols/ProtocolInstancePanel/useElementInputs';
import { useElementNamePopover } from 'client/app/apps/workflow-builder/panels/element-instance-panel/hooks';
import { Parameter } from 'common/types/bundle';

type Props = {
  stepsConfig: StepParamConfigById;
  activeStepId: string;
  elementInstanceId: string;
  elementInstanceName: string;
  inputs: { parameter: Parameter; value: any }[];
  onChange: (input: CreateInputStepState, checked: boolean) => void;
  onClose: () => void;
};

const ProtocolElementInputInstancePanel = ({
  stepsConfig,
  activeStepId,
  elementInstanceId,
  elementInstanceName,
  inputs: parametersWithValues,
  onChange,
  onClose,
}: Props) => {
  const { elementNamePopover, ...elementNamePopoverEvents } =
    useElementNamePopover(elementInstanceName);

  const { inputParameters, handleChangeInput } = useElementInputs(
    elementInstanceId,
    elementInstanceName,
    parametersWithValues,
    stepsConfig,
    activeStepId,
    onChange,
  );

  return (
    <ProtocolInstancePanel
      title={elementInstanceName}
      onClose={onClose}
      {...elementNamePopoverEvents}
    >
      <StyledStack>
        {inputParameters.map(input => (
          <ProtocolItem
            key={`${elementInstanceId}-${input.parameter.name}`}
            onToggle={(checked, editor, value, key) =>
              handleChangeInput(input.parameter, checked, editor, value, key)
            }
            elementId={elementInstanceId}
            parameter={input.parameter}
            enabledKeys={input.enabledKeys}
            value={input.value}
            checked={input.isEnabled}
            isDisabled={input.isEnabled && input.otherStepMembership !== undefined}
            otherStepMembership={input.otherStepMembership}
          />
        ))}
      </StyledStack>
      {elementNamePopover}
    </ProtocolInstancePanel>
  );
};

const StyledStack = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(2),
  padding: spacing(0, 2),
}));

export default ProtocolElementInputInstancePanel;
