import React, { PropsWithChildren, useState } from 'react';

import Box from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { CardExpander } from 'client/app/apps/protocols/EntityCardExpander/CardExpander';
import Colors from 'common/ui/Colors';

const EXPAND_BUTTON_WIDTH = '34px';
const ENTITY_CARD_EXPANDABLE_GRID_TEMPLATE_COLUMNS = `[expand] ${EXPAND_BUTTON_WIDTH} [card] 1fr`;

type Props = {
  whenExpanded: React.ReactNode;
};

const EntityCardExpander = ({ children, whenExpanded }: PropsWithChildren<Props>) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card variant="outlined" expanded={expanded}>
      <CardExpander
        expanded={expanded}
        onClick={() => setExpanded(expanded => !expanded)}
      />
      <Box sx={{ gridColumn: 'card' }}>{children}</Box>
      {expanded && (
        <Box sx={{ gridColumn: '1 / -1', overflow: 'hidden' }}>{whenExpanded}</Box>
      )}
    </Card>
  );
};
type CardProps = {
  expanded?: boolean;
} & PaperProps;

const Card = styled(Paper, {
  shouldForwardProp: prop => prop !== 'expanded',
})<CardProps>(({ expanded }) => ({
  display: 'grid',
  gridTemplateColumns: ENTITY_CARD_EXPANDABLE_GRID_TEMPLATE_COLUMNS,
  gridTemplateRows: expanded ? 'auto 1fr' : 'auto',
  alignItems: 'stretch',
  borderRadius: '8px',
  overflow: 'hidden',
  ...(expanded && {
    borderColor: Colors.INDIGO_90,
  }),
}));

export { EntityCardExpander };
