import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';

type Props = {
  showVersion?: boolean;
};

const RecentProtocolInstancesListHeader = ({ showVersion }: Props) => {
  return (
    <Wrapper>
      <Typography variant="overline" sx={{ gridColumn: 'name' }}>
        Recent Instances
      </Typography>
      {showVersion && (
        <Typography variant="overline" sx={{ gridColumn: 'version' }}>
          Version
        </Typography>
      )}
      <Typography variant="overline" sx={{ gridColumn: 'author' }}>
        Created by
      </Typography>
      <Typography variant="overline" sx={{ gridColumn: 'date' }}>
        Creation Date
      </Typography>
    </Wrapper>
  );
};

export const ENTITY_CARD_COLUMN_TEMPLATE =
  '[trailer] 22px [name] minmax(30%, 1fr) [version] min-content [author] 120px [date] minmax(60px, 188px) [placeholder] 10px [end]';

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: ENTITY_CARD_COLUMN_TEMPLATE,
  gap: theme.spacing(5),
  backgroundColor: Colors.GREY_20,
  height: '48px',
  overflow: 'hidden',
  alignItems: 'center',
}));

export { RecentProtocolInstancesListHeader };
