import React, { PropsWithChildren, useState } from 'react';

import { useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { QUERY_PROTOCOL_VERSIONS, QUERY_UNIQUE_TAGS } from 'client/app/api/gql/queries';
import { usePublishProtocolAndNavigate } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { SectionHeading } from 'client/app/apps/protocols/dialogs/PublishProtocolDialog/SectionHeading';
import { HasPermission } from 'client/app/components/Permissions';
import { TopBar } from 'client/app/components/TopBar';
import IconButton from 'common/ui/components/IconButton';
import { TagInput } from 'common/ui/components/TagInput';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = DialogProps<void> & {
  id: ProtocolId;
  version: ProtocolVersion;
};

export const PublishProtocolDialog = ({ isOpen, onClose, id, version }: Props) => {
  const { handlePublishProtocolAndNavigate, loading } = usePublishProtocolAndNavigate();
  const versionsQuery = useQuery(QUERY_PROTOCOL_VERSIONS, {
    variables: {
      id,
    },
  });
  const hasPreviouslyBeenPublished = versionsQuery.data?.protocols.items.some(
    p => p.isPublished,
  );

  const { data } = useQuery(QUERY_UNIQUE_TAGS, {
    variables: {
      entityClassName: 'Protocol',
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const handleOnClose = () => {
    onClose();
  };

  const [tags, setTags] = useState<string[]>([]);
  const [isPublic, setIsPublic] = useState(false);

  const handleOnPublish = () => {
    void handlePublishProtocolAndNavigate(id, version, isPublic, tags);
  };
  const options = data?.uniqueTags || [];

  const tagHelp = 'Adding tags makes it easier to find Protocols';
  const visibilityHelp =
    'Controls who in your organisation will be able to see and use the published Protocol';
  return (
    <Dialog open={isOpen} onClose={handleOnClose}>
      <StyledDialogHeader>
        <Typography variant="h3">
          {hasPreviouslyBeenPublished ? 'Publish updated protocol' : 'Publish protocol'}
        </Typography>
        <StyledDialogHeaderRightContentContainer>
          <IconButton
            icon={<CloseIcon />}
            onClick={handleOnClose}
            color="inherit"
            size="small"
            disabled={loading}
          />
        </StyledDialogHeaderRightContentContainer>
      </StyledDialogHeader>
      {hasPreviouslyBeenPublished && (
        <StyledTopBar>
          <InfoIcon />
          <Typography>
            When published, this protocol will appear as version {version} and replace the
            previously published version.
          </Typography>
        </StyledTopBar>
      )}
      <Main>
        <Stack gap={6}>
          <Section>
            <SectionHeading title="Tags" help={tagHelp} />
            <SectionContent>
              <TagInput
                tags={tags}
                options={options}
                placeholder="Start typing to select a tag or press + to add new one"
                onTagsChange={setTags}
              />
            </SectionContent>
          </Section>
          <Section>
            <SectionHeading title="Visibility" help={visibilityHelp} />
            <SectionContent>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography>Only You</Typography>
                <HasPermission
                  permission="publicise:protocol"
                  renderItem={hasPermission => (
                    <Switch
                      checked={isPublic}
                      onChange={e => setIsPublic(e.target.checked)}
                      disabled={!hasPermission}
                    />
                  )}
                  toolTipCopy="Only admin users may make a protocol visible to everyone"
                />
                <Typography>Everyone</Typography>
              </Stack>
            </SectionContent>
          </Section>
        </Stack>
      </Main>
      <Actions>
        <Button color="primary" onClick={handleOnPublish} sx={{ padding: 0 }}>
          {hasPreviouslyBeenPublished ? 'Update' : 'Publish'}
        </Button>
      </Actions>
    </Dialog>
  );
};

const Section = ({ children }: PropsWithChildren<{}>) => {
  return <Stack gap={3}>{children}</Stack>;
};

const SectionContent = styled('div')(({ theme: { spacing } }) => ({
  marginLeft: spacing(3),
}));

const StyledTopBar = styled(TopBar)(({ theme: { spacing } }) => ({
  margin: spacing(0, 0, 4, 0),
  padding: spacing(3, 6),
}));

const StyledDialogHeader = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '38px',
  padding: spacing(6),
  minWidth: '600px',
}));

const Main = styled('main')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(5),
  padding: spacing(0, 6),

  overflow: 'auto',
}));

const Actions = styled(DialogActions)(({ theme: { spacing } }) => ({
  padding: spacing(6),
}));

const StyledDialogHeaderRightContentContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
