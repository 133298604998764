import React from 'react';

import Box from '@mui/material/Box';

import { ProtocolInstancesListItem } from 'client/app/apps/protocols/RecentProtocolInstanceList/ProtocolInstanceListItem';
import { ProtocolInstancesDialog } from 'client/app/apps/protocols/RecentProtocolInstanceList/ProtocolInstancesDialog';
import { RecentProtocolInstancesListFooter } from 'client/app/apps/protocols/RecentProtocolInstanceList/RecentProtocolInstancesListFooter';
import { RecentProtocolInstancesListHeader } from 'client/app/apps/protocols/RecentProtocolInstanceList/RecentProtocolInstancesListHeader';
import { ProtocolInstancesQuery } from 'client/app/gql';
import { protocolInstanceRoutes } from 'client/app/lib/nav/actions';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  data: ProtocolInstancesQuery;
  protocolId: ProtocolId;
};

const ProtocolInstancesList = ({ data, protocolId }: Props) => {
  const [protocolInstancesDialog, openProtocolInstancesDialog] = useDialog(
    ProtocolInstancesDialog,
  );

  const instances = data.protocolInstances.items || [];
  const latestVersion = Math.max(
    ...instances.map(i => i.protocol.version),
  ) as ProtocolVersion;

  const instancesToShow = instances.slice(0, 5);
  const showVersion = instancesToShow.some(i => i.protocol.version !== latestVersion);

  const { navigate } = useNavigation();
  const handleOnClick = (id: string) => {
    navigate(protocolInstanceRoutes.editProtocolInstance, {
      id,
    });
  };

  const handleOpenProtocolInstancesDialog = async (protocolId: ProtocolId) => {
    await openProtocolInstancesDialog({ protocolId });
  };

  return (
    <Box>
      <RecentProtocolInstancesListHeader showVersion={showVersion} />
      {instancesToShow.map(instance => (
        <ProtocolInstancesListItem
          key={instance.id}
          instance={instance}
          showVersion={showVersion}
          handleOnClick={() => handleOnClick(instance.id)}
        />
      ))}
      {instances.length > instancesToShow.length && (
        <RecentProtocolInstancesListFooter
          onClick={() => handleOpenProtocolInstancesDialog(protocolId)}
        />
      )}
      {protocolInstancesDialog}
    </Box>
  );
};

export { ProtocolInstancesList };
