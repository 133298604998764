import { v4 as uuid } from 'uuid';

import { Markdown } from 'common/lib/markdown';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import { ProtocolStep, SourceDescription } from 'common/types/Protocol';
import { arePathsEqual, ElementPath, newElementPath, Schema } from 'common/types/schema';

/**
 * StepState represents the state of a step both in terms of presentation in
 * the protocol and function in the workflow schema. As such it is meant to be
 * the single source of truth for step information
 */
export type StepState = {
  id: string;
  displayName: string;
  displayDescription?: Markdown;
  inputs: InputStepState[];
  outputs: OutputStepState[];
};

export type InputStepState<InputStepStateId = string> = {
  id: InputStepStateId;
  typeName: string;
  path: ElementPath;
  displayName: string;
  sourceDescription?: SourceDescription;
  default?: any;
  contextId?: string;
  displayDescription?: Markdown;
  configuration: ParameterEditorConfigurationSpec;
  linked?: {
    id: InputStepStateId;
    path: ElementPath;
    sourceDescription?: SourceDescription;
  }[];
};

export type OutputStepState = {
  id: string;
  typeName: string;
  path: ElementPath;
  displayName: string;
  displayDescription?: Markdown;
  sourceDescription?: SourceDescription;
};

export const newStepStates = (schema: Schema, steps: ProtocolStep[]) => {
  const temporaryPath = newElementPath('temporary', 'entry');

  // notice that we init with a temporary path, update based on schema and
  // remove any temporary paths afterwards. This means the schema is the source
  // of truth on re-initialisation
  const stepStates = steps.map<StepState>(step => {
    return {
      id: step.id,
      displayName: step.displayName,
      displayDescription: step.displayDescription,
      errors: [],
      inputs: step.inputs.map<InputStepState>(input => {
        return {
          id: input.id,
          path: temporaryPath,
          typeName: '',
          displayName: input.displayName,
          displayDescription: input.displayDescription,
          sourceDescription: input.sourceDescription,
          configuration: input.configuration,
          linked: input.linked?.map(({ id, sourceDescription }) => ({
            id,
            path: temporaryPath,
            sourceDescription,
          })),
        };
      }),
      outputs: step.outputs.map<OutputStepState>(output => {
        return {
          id: output.id,
          path: temporaryPath,
          typeName: '',
          displayName: output.displayName,
          displayDescription: output.displayDescription,
          sourceDescription: output.sourceDescription,
        };
      }),
    };
  });

  schema.inputs?.forEach(input => {
    stepStates.some(step => {
      const state = step.inputs.find(({ id, linked }) => {
        return id === input.id || linked?.some(({ id }) => id === input.id);
      });
      if (!state) {
        return false;
      }
      if (state.id === input.id) {
        state.path = input.path;
        state.typeName = input.typeName;
        state.default = input.default;
        state.contextId = input.contextId;
        return true;
      }
      const linkedIndex = state.linked!.findIndex(({ id }) => id === input.id);
      const newLink = {
        id: input.id,
        path: input.path,
        sourceDescription: state.sourceDescription,
      };
      state.linked = state.linked?.toSpliced(linkedIndex, 1, newLink);
      return true;
    });
  });

  schema.outputs?.forEach(output => {
    stepStates.some(step => {
      const state = step.outputs.find(({ id }) => id === output.id);
      if (state) {
        state.path = output.path;
        state.typeName = output.typeName;
        return true;
      }
      return false;
    });
  });

  stepStates.forEach(step => {
    step.inputs = step.inputs.filter(({ path }) => path !== temporaryPath);
    step.outputs = step.outputs.filter(({ path }) => path !== temporaryPath);
  });

  return stepStates;
};

type StepStateUpdate = {
  input?: {
    add?: InputStepState;
    updateByIndex?: { index: number; value?: InputStepState };
    removeByPath?: ElementPath;
  };
  output?: {
    add?: OutputStepState;
    updateByIndex?: { index: number; value?: OutputStepState };
    removeByPath?: ElementPath;
  };
};

export const updateStepStates = (
  steps: StepState[],
  index: number,
  opts: StepStateUpdate,
) => {
  const step = steps[index];
  const update = { ...step };
  const { input, output } = opts;
  if (input) {
    const { add, updateByIndex, removeByPath } = input;
    if (add) {
      update.inputs = [...update.inputs, add];
    }
    if (updateByIndex) {
      const { index: inputIndex, value } = updateByIndex;
      update.inputs =
        value === undefined
          ? update.inputs.toSpliced(inputIndex, 1)
          : update.inputs.toSpliced(inputIndex, 1, value);
    }
    if (removeByPath) {
      update.inputs = update.inputs.filter(i => !arePathsEqual(i.path, removeByPath));
    }
  }
  if (output) {
    const { add, updateByIndex, removeByPath } = output;
    if (add) {
      update.outputs = [...update.outputs, add];
    }
    if (updateByIndex) {
      const { index: outputIndex, value } = updateByIndex;
      update.outputs =
        value === undefined
          ? update.outputs.toSpliced(outputIndex, 1)
          : update.outputs.toSpliced(outputIndex, 1, value);
    }
    if (removeByPath) {
      update.outputs = update.outputs.filter(o => !arePathsEqual(o.path, removeByPath));
    }
  }
  return steps.toSpliced(index, 1, update);
};

export type CreateInputStepState = {
  element: { instanceName: string; id: string };
  parameter: {
    name: string;
    displayName: string;
    editor: ParameterEditorConfigurationSpec;
    /** e.g. value object key (string) or array index (number) */
    extraKey?: string | number;
  };
  value: any;
};

export function newInputStepState({
  element,
  parameter,
  value,
}: CreateInputStepState): InputStepState {
  const key = parameter.extraKey;
  const hasKey = key !== undefined;
  const isKeyFromArray = typeof key === 'number';

  // element path expects all string keys including indexes into arrays
  const extraKeys = hasKey ? [`${key}`] : undefined;

  // humans prefer 1-indexed numbers, and language that they understand about the key
  const smallRightTriangle = ' \u25B8 ';
  const displayKey = isKeyFromArray ? `${key + 1}` : key;
  const displayName = hasKey
    ? `${parameter.displayName} ${smallRightTriangle} ${displayKey}`
    : parameter.displayName;
  const extraDescriptions = hasKey
    ? [isKeyFromArray ? `Position: ${displayKey}` : `Entry: ${displayKey}`]
    : undefined;

  return {
    id: uuid(),
    path: newElementPath(element.id, parameter.name, extraKeys),
    typeName: '', // do we still need this? Backend does nothing with it...
    displayName,
    displayDescription: '' as Markdown,
    sourceDescription: {
      elementInstanceName: element.instanceName,
      displayName,
      extraDescriptions,
    },
    configuration: parameter.editor,
    // set null so that on first creation of protocol instances the input has a
    // value that will serialize if the user enters none
    default: value === undefined ? null : value,
  };
}

export type CreateOutputStepState = {
  element: { instanceName: string; id: string };
  parameter: { name: string; typeName: string; displayName: string };
};

export function newOutputStepState({
  element,
  parameter,
}: CreateOutputStepState): OutputStepState {
  return {
    id: uuid(),
    path: newElementPath(element.id, parameter.name),
    typeName: parameter.typeName, // needed to infer output preview
    displayName: parameter.displayName,
    sourceDescription: {
      elementInstanceName: element.instanceName,
      displayName: parameter.displayName,
    },
  };
}
