import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import uniqBy from 'lodash/uniqBy';

import isWorkflowReadonly from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import {
  ConfiguredDevice,
  emptyWorkflowConfig,
  WorkflowConfig,
} from 'common/types/bundle';
import {
  hasDispenserDevice,
  hasManualDevice,
  hasPeripheralDeviceOnly,
  isDataOnly,
} from 'common/types/bundleConfigUtils';

export default function WorkflowSettingsStateContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const workflowConfig = useWorkflowBuilderSelector(state => state.config);
  const editMode = useWorkflowBuilderSelector(state => state.editMode);
  const source = useWorkflowBuilderSelector(state => state.source);
  const selectedStageId = useWorkflowBuilderSelector(state => state.selectedStageId);
  const stages = useWorkflowBuilderSelector(state => state.stages);

  const isReadonly = isWorkflowReadonly(editMode, source);

  const workflowConfigProps = useMemo(() => {
    const allConfiguredDevices = uniqBy(
      [...(workflowConfig.configuredDevices ?? [])],
      'id',
    );

    const someDeviceSelected = allConfiguredDevices.length > 0;

    const selectedStage = stages.find(stage => stage.id === selectedStageId);
    const configuredDevicesForSelectedStage = selectedStage
      ? allConfiguredDevices.filter(device =>
          selectedStage.configuredDevices.includes(device.id),
        )
      : [];

    const isDataOnlyMode = isDataOnly(workflowConfig);
    const isDispenserMode = hasDispenserDevice(workflowConfig);
    const isManualMode = hasManualDevice(workflowConfig);
    const isPeripheralOnly = hasPeripheralDeviceOnly(workflowConfig);

    const requiresDevice = workflowConfig.global.requiresDevice === true;
    const showDeviceSelectorCard = someDeviceSelected || isManualMode || isDataOnlyMode;

    const showCustomLHPolicies =
      someDeviceSelected &&
      !isManualMode &&
      !isDataOnlyMode &&
      !isDispenserMode &&
      !isPeripheralOnly;

    const showDeckOptions =
      someDeviceSelected &&
      !isManualMode &&
      !isDataOnlyMode &&
      !isDispenserMode &&
      !isPeripheralOnly;

    return {
      allConfiguredDevices,
      configuredDevicesForSelectedStage,
      requiresDevice,
      showCustomLHPolicies,
      showDeckOptions,
      showDeviceSelectorCard,
    };
  }, [selectedStageId, stages, workflowConfig]);

  return (
    <WorkflowSettingsStateContext.Provider
      value={useMemo(
        () => ({ isReadonly, workflowConfig, ...workflowConfigProps }),
        [isReadonly, workflowConfig, workflowConfigProps],
      )}
    >
      {children}
    </WorkflowSettingsStateContext.Provider>
  );
}

type WorkflowSettingsState = {
  allConfiguredDevices: ConfiguredDevice[];
  configuredDevicesForSelectedStage: ConfiguredDevice[];
  workflowConfig: WorkflowConfig;
  isReadonly: boolean;
  requiresDevice: boolean;
  showDeckOptions: boolean;
  showDeviceSelectorCard: boolean;
  showCustomLHPolicies: boolean;
};

const WorkflowSettingsStateContext = createContext<WorkflowSettingsState>({
  allConfiguredDevices: [],
  configuredDevicesForSelectedStage: [],
  workflowConfig: emptyWorkflowConfig(),
  isReadonly: false,
  requiresDevice: false,
  showDeckOptions: false,
  showDeviceSelectorCard: false,
  showCustomLHPolicies: false,
});

export function useWorkflowSettingsState() {
  return useContext(WorkflowSettingsStateContext);
}
