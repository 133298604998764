import React, { useCallback, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useCreateProtocolInstanceAndNavigate } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { VersionSelector } from 'client/app/apps/protocols/dialogs/ProtocolDetailsDialog/VersionSelector';
import ExampleSimulation from 'client/app/apps/protocols/ExampleSimulation';
import { useProtocolListActionContext } from 'client/app/apps/protocols/ProtocolListActionProvider';
import FabWithFX from 'client/app/apps/workflow-builder/FabWithFX';
import { ProtocolDetailsQuery } from 'client/app/gql';
import IconButton from 'common/ui/components/IconButton';
import { MessagePreview } from 'common/ui/components/MessagePreview';
import { ScrollGuard } from 'common/ui/components/ScrollGuard';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import { ProtocolIcon } from 'common/ui/icons';

type Props = {
  data: ProtocolDetailsQuery;
  onClose: () => void;
  setSelectedVersion: (version: ProtocolVersion) => void;
};

export function ProtocolDetails({ data, onClose, setSelectedVersion }: Props) {
  const protocol = data.protocol.entity;
  const [isBusy, setIsBusy] = useState(false);
  const { handleEditProtocol } = useProtocolListActionContext();

  const { handleCreateProtocolInstanceAndNavigate } =
    useCreateProtocolInstanceAndNavigate();

  const handleOnClick = useCallback(async () => {
    setIsBusy(true);
    if (protocol.isPublished)
      await handleCreateProtocolInstanceAndNavigate(protocol.id, protocol.version);
    else handleEditProtocol(protocol.id, protocol.version);
  }, [
    handleCreateProtocolInstanceAndNavigate,
    handleEditProtocol,
    protocol.id,
    protocol.isPublished,
    protocol.version,
  ]);

  const ctaText = useMemo(
    () => (protocol.isPublished ? 'Use protocol' : 'Continue editing'),
    [protocol.isPublished],
  );

  return (
    <>
      <StyledDialogHeader>
        <TypographyWithTooltip variant="h2">{protocol.name}</TypographyWithTooltip>
        <StyledDialogHeaderRightContentContainer>
          <VersionSelector
            id={protocol.id}
            selected={protocol.version}
            setSelected={setSelectedVersion}
          />
          <StyledDivider orientation="vertical" flexItem />
          <FabWithFX
            busy={isBusy}
            color="primary"
            onClick={handleOnClick}
            size="medium"
            variant="extended"
            disabled={!!protocol.isDeleted}
          >
            <Stack gap={3} direction="row" alignItems="center">
              <ProtocolIcon />
              {ctaText}
            </Stack>
          </FabWithFX>
          <IconButton
            icon={<CloseIcon />}
            onClick={onClose}
            color="inherit"
            size="small"
          />
        </StyledDialogHeaderRightContentContainer>
      </StyledDialogHeader>
      <Stack
        gap={5}
        overflow="auto"
        paddingRight={3}
        marginRight={-3}
        height="100%"
        direction="row"
      >
        <Stack height={80} flexGrow={1} gap={6}>
          <Stack gap={3}>
            <Typography variant="h2">Summary</Typography>
            <Typography variant="body1">{protocol.shortDescription}</Typography>
          </Stack>
          <Stack>
            <Typography variant="h2">Description</Typography>
            <Typography variant="body1" component="div">
              <MessagePreview
                message={protocol.protocol.displayDescription}
                messageType="markdown"
              />
            </Typography>
          </Stack>
          <Stack>
            <ScrollGuard deactivateOnMouseLeave>
              <ExampleSimulation simulation={protocol.exampleSimulation ?? undefined} />
            </ScrollGuard>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export function ProtocolDetailsSkeleton({ onClose }: { onClose: () => void }) {
  return (
    <>
      <StyledDialogHeader>
        <Skeleton width="20em" height="4em" />
        <StyledDialogHeaderRightContentContainer>
          <IconButton
            icon={<CloseIcon />}
            onClick={onClose}
            color="inherit"
            size="small"
          />
        </StyledDialogHeaderRightContentContainer>
      </StyledDialogHeader>
      <Stack gap={5} paddingRight={3} marginRight={-3} direction="column">
        <Skeleton width="60%" height="2em" />
        <Skeleton width="60%" height="2em" />
        <Skeleton width="60%" height="2em" />
      </Stack>
    </>
  );
}

const StyledDialogHeader = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  minHeight: '38px',
  marginBottom: spacing(5),
  gap: spacing(3),
}));

const StyledDialogHeaderRightContentContainer = styled('div')(
  ({ theme: { spacing } }) => ({
    marginLeft: ' auto',
    display: 'flex',
    alignItems: 'center',
    gap: spacing(3),
  }),
);

const StyledDivider = styled(Divider)({
  // Center vertically
  alignSelf: 'center',
  height: '24px',
});
