import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { ENTITY_CARD_COLUMN_TEMPLATE } from 'client/app/apps/protocols/RecentProtocolInstanceList/RecentProtocolInstancesListHeader';
import { ProtocolInstancesQuery } from 'client/app/gql';
import { formatDateTime } from 'common/lib/format';
import Colors from 'common/ui/Colors';

type Props = {
  instance: ProtocolInstancesQuery['protocolInstances']['items'][number];
  showVersion?: boolean;
  handleOnClick: () => void;
};

const ProtocolInstancesListItem = ({ instance, showVersion, handleOnClick }: Props) => {
  return (
    <Content key={instance.id} onClick={() => handleOnClick()}>
      <Typography variant="overline" sx={{ gridColumn: 'name' }}>
        {instance.name}
      </Typography>
      {showVersion && (
        <Typography variant="overline" sx={{ gridColumn: 'version' }}>
          {instance.protocol.version}
        </Typography>
      )}
      <Typography variant="overline" sx={{ gridColumn: 'author' }}>
        {instance.createdBy.displayName}
      </Typography>
      <Typography variant="overline" sx={{ gridColumn: 'date' }}>
        {formatDateTime(new Date(instance.createdAt))}
      </Typography>
    </Content>
  );
};

const Content = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: ENTITY_CARD_COLUMN_TEMPLATE,
  gap: theme.spacing(5),
  height: '48px',
  overflow: 'hidden',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    background: Colors.BLUE_0,
  },
}));

export { ProtocolInstancesListItem };
