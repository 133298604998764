import React, { PropsWithChildren } from 'react';

import produce, { castDraft, Draft, enableMapSet } from 'immer';
import isEmpty from 'lodash/isEmpty';
import { createContext, useContextSelector } from 'use-context-selector';
import { v4 as uuid } from 'uuid';

import { resetOutputPreviewState } from 'client/app/apps/workflow-builder/output-preview/outputPreviewUtils';
import {
  OutputPreviewDispatch,
  OutputPreviewState,
} from 'client/app/apps/workflow-builder/output-preview/types';
import {
  AdditionalPanelContent,
  isAdditionalPanelChildOfElementInstance,
  PanelContent,
} from 'client/app/apps/workflow-builder/panels/Panel';
import { ElementDetailsTabs } from 'client/app/components/ElementDetails/ElementDetails';
import { OutputEntity } from 'client/app/components/ElementPlumber/ElementOutputs/types';
import {
  ArrayElement,
  ContentType,
  DeviceParsedRunConfigQuery,
  ElementSetQuery,
} from 'client/app/gql';
import { getDropTargetGroup } from 'client/app/lib/layout/GroupHelper';
import {
  BUILDER_CONTROLS_PADDING_LEFT_RIGHT,
  ELEMENT_INSTANCE_WIDTH,
  getGroupDimensions,
} from 'client/app/lib/layout/LayoutHelper';
import cloneWithUUID, { Identifiable } from 'client/app/lib/workflow/cloneWithUUID';
import normaliseLayoutPositions from 'client/app/lib/workflow/normaliseLayoutPositions';
import { SimulationNotificationDetails } from 'client/app/lib/workflow/types';
import {
  canDeselectObject,
  createElementGroup,
  createEmptyElementGroup,
  getSelectedElementsForGroup,
  resizeGroup,
  updateElementGroupMembership,
} from 'client/app/state/elementGroupUtils';
import {
  addAndSetNamedPlate,
  addLabwarePreference,
  getLabwarePreferencesAddedOrderFromConfig,
  insertLabwarePreference,
  LabwarePreference,
  LabwareType,
  removeAllLabwareTypePreferences,
  removeLabwarePreference,
  removeNamedPlate,
  renameNamedPlate,
  setDefaultConfig,
} from 'client/app/state/LabwarePreference';
import {
  assignElementsToStages,
  assignElementToStage,
  checkDragValidity,
  formatDefaultLayoutOptions,
  getElementFromSelection,
  isNewAddedElement,
  resetDragState,
  setDragDelta,
  workflowRequiresDevice,
} from 'client/app/state/workflowBuilderStateUtils';
import { DATA_ONLY_DUMMY_DEVICE } from 'common/constants/manual-device';
import { arrayIsFalsyOrEmpty } from 'common/lib/data';
import {
  BundleParameters,
  ConfiguredDevice,
  ConfiguredDeviceId,
  Connection,
  CoreError,
  EditorType,
  ElementContextMap,
  ElementInstance,
  ElementInstanceMeta,
  emptyWorkflowConfig,
  FactorItem,
  FactorPath,
  Factors,
  Group,
  hasDeck,
  ParameterValue,
  ParameterValueDict,
  Stage,
  TemplateWorkflow,
  WorkflowConfig,
  WorkflowEditMode,
} from 'common/types/bundle';
import {
  hasDispenserOrManualDevice,
  hasPeripheralDeviceOnly,
  updateConfigAfterSet,
} from 'common/types/bundleConfigUtils';
import { Dimensions } from 'common/types/Dimensions';
import { Position2d } from 'common/types/Position';
import { removeElementsFromSchema, Schema } from 'common/types/schema';
import { ErrorContext } from 'common/types/simulation_types';
import { useUndoReducer } from 'common/ui/hooks/useUndoReducer';
import { isPositionInRect } from 'common/ui/lib/position';

// Bear with me, O Reader, as I recount the tale of how this client state
// management system came to be...

// Once upon a time, Antha was a Polymer web app. This was before the
// narrator's time at Synthace, but according to accounts gathered from
// eyewitnesses, it was not ideal.

// In The 2018/2019 Rewrite, this was replaced with React and Redux for state
// management in the Workflow Builder. Over time, with the comings and goings
// of team members, the level of knowledge of Redux in the team diminished on
// average and it might be said that as in many cases in life, the team came to
// fear that which they did not understand. Instead, they opted to use Apollo
// and push as much state management into the server wherever possible.

// After avoiding contemplating the Redux situation for months on end, things
// finally came to a head. "Jotai?". "Recoil?!". The clamouring to be rid of
// Redux began. It was at this point that the narrator updated the existing
// Redux implementation to use Redux Toolkit, bringing with it easy immutable
// updates and a large reduction in boilerplate. The Redux model was no longer
// a mystery to the Core Interfaces team.

// Despite these efforts, however, the fact remained that the majority of
// screens in Antha are largely rather simple and Redux precludes the use of
// Suspense! So, rather than keep Redux, it was proposed that we stick to using
// useReducer + useContext, and use use-context-selector to avoid an avalanche of
// re-renders. And so we find this code as it is today. We have come so far,
// and yet there is still a way to go.

// We do have Set in our state, we need to tell immer to support it.
enableMapSet();

type GraphQLElementSet = ElementSetQuery['elementSet'];
type WorkflowBuilderMode = 'Build' | 'DOE';

export type WorkflowBuilderAction =
  | { type: 'addConnection'; payload: Connection }
  | {
      type: 'addElementInstance';
      payload: {
        elementInstance: ElementInstance;
        parameters: ParameterValueDict;
      };
    }
  | {
      type: 'addPastedObjects';
      payload: {
        connections: Identifiable<Connection>[];
        parameters: BundleParameters;
        elementInstances: ElementInstance[];
        elementGroups: Group[];
      };
    }
  | {
      type: 'applyDragDelta';
    }
  | { type: 'deleteSelectedObjects' }
  | { type: 'deselectAll' }
  | {
      type: 'renameElementInstance';
      payload: { id: string; newName: string };
    }
  | { type: 'resetToWorkflow'; payload: State; preserveKeys?: (keyof State)[] }
  | { type: 'selectAll' }
  | { type: 'setConfig'; payload: WorkflowConfig }
  | { type: 'setConfigToNoDevices' }
  | { type: 'setLabwarePreferenceType'; payload: LabwareType | undefined }
  | { type: 'addLabwarePreference'; payload: LabwarePreference }
  | {
      type: 'insertLabwarePreference';
      payload: LabwarePreference & { idx: number };
    }
  | { type: 'removeLabwarePreference'; payload: LabwarePreference }
  | { type: 'removeAllLabwareTypePreferences'; payload: LabwareType }
  | { type: 'addAndSetNamedPlate'; payload: string }
  | { type: 'removeNamedPlate'; payload: string }
  | { type: 'renameNamedPlate'; payload: { oldName: string; newName: string } }
  | { type: 'setDragDelta'; payload: { delta: Position2d; id: string } }
  | { type: 'nudgeSelectedObjects'; payload: Position2d }
  | {
      type: 'updateParameter';
      payload: {
        parameterName: string;
        instanceName: string;
        value: ParameterValue;
      };
    }
  | {
      type: 'updateAllParameters';
      payload: {
        instanceName: string;
        parameterValues: ParameterValueDict;
      };
    }
  | {
      type: 'updatePendingParameter';
      payload: {
        parameterName: string;
        instanceName: string;
        value: ParameterValue;
      };
    }
  | {
      type: 'clearStagedParameters';
    }
  | {
      type: 'setInstanceAnnotation';
      payload: { instanceID: string; annotation: string | undefined };
    }
  | { type: 'setSelectedObjects'; payload: string[] }
  | { type: 'setErroredObjects'; payload: string[] }
  | { type: 'setSelectedDevices'; payload: ConfiguredDevice[] }
  | {
      type: 'setDeviceRunConfig';
      payload: {
        configuredDeviceId: string;
        runConfigId?: string;
        runConfigVersion?: number;
        runConfiguration?: DeviceParsedRunConfigQuery;
      };
    }
  | { type: 'setWorkflowName'; payload: string }
  | { type: 'snapLayoutToOrigin' }
  | { type: 'toggleSelectedObjects'; payload: string[] }
  | {
      type: 'showErroredElementInstance';
      payload: ErrorContext;
    }
  | {
      type: 'setActivePanel';
      payload: PanelContent;
    }
  | {
      type: 'setAdditionalPanel';
      payload: AdditionalPanelContent;
    }
  | {
      type: 'saveSelectedDevices';
      payload: {
        runConfiguration: DeviceParsedRunConfigQuery | undefined;
        configuredDevices: ConfiguredDevice[] | undefined;
      };
    }
  | {
      type: 'createElementGroupFromSelection';
    }
  | { type: 'createElementGroupFromMouseArea'; payload: Dimensions }
  | { type: 'deleteElementGroup'; payload: string }
  | { type: 'updateElementGroup'; payload: Pick<Group, 'id'> & Partial<Group> }
  | {
      type: 'setElementGroupDescription';
      payload: { id: string; description?: string };
    }
  | {
      type: 'setVisibleCanvasArea';
      payload: Dimensions | undefined;
    }
  | { type: 'resizeElementGroupToFit'; payload: string }
  | {
      type: 'setElementInstanceSize';
      payload: {
        elementId: string;
      } & Pick<ElementInstanceMeta, 'bodySize' | 'nameSize'>;
    }
  | { type: 'updateElementsWithContexts'; payload: ElementContextMap }
  | {
      type: 'centerToElement';
      payload: { elementId: string; selectElement?: boolean };
    }
  | {
      type: 'updateFactors';
      payload: { factorsToAdd?: FactorItem[]; factorsToRemove?: string[] };
    }
  | {
      type: 'toggleFactorEditing';
      payload: {
        selectedFactorElement?: string;
        selectedFactorParameter?: string;
      };
    }
  | {
      type: 'toggleFactor';
      payload: {
        elementName: string;
        parameterName: string;
        factorised: boolean;
      };
    }
  | {
      type: 'switchMode';
      payload: WorkflowBuilderMode;
    }
  | {
      type: 'setElementValidationVisible';
      payload: boolean;
    }
  | {
      type: 'setElementContextError';
      payload: CoreError | null;
    }
  | {
      type: 'setIsSaving';
      payload: boolean;
    }
  | {
      type: 'setSelectedStageId';
      payload: { id: string | undefined; isThroughTimeline: boolean };
    }
  | { type: 'setStageDragDelta'; payload: number }
  | { type: 'applyStageDragDelta' }
  | {
      type: 'updateStageName';
      payload: {
        stageId: string;
        newName: string;
      };
    }
  | { type: 'addNewStage' }
  | { type: 'deleteStage'; payload: string }
  | { type: 'applyNudge' }
  | {
      type: 'openOutputPreview';
      payload: OutputPreviewDispatch;
    }
  | { type: 'setOutputPreviewEntityView'; payload: OutputEntity | undefined }
  | { type: 'selectPlateInOutputPreview'; payload: string | undefined }
  | { type: 'closeOutputPreview' }
  | { type: 'setElementInstancePanelTab'; payload: ElementDetailsTabs }
  | { type: 'fixDevices' };

function workflowBuilderReducer(state: State, action: WorkflowBuilderAction) {
  switch (action.type) {
    case 'addConnection':
      return produce(state, draft => addConnection(draft, action.payload));
    case 'addElementInstance': {
      const { elementInstance, parameters } = action.payload;

      return produce(state, draft => {
        const newElementInstance: ElementInstance = {
          ...elementInstance,
          Meta: {
            ...elementInstance.Meta,
            status: 'neutral',
            errors: [],
          },
        };

        draft.elementInstances.push(newElementInstance as Draft<ElementInstance>);
        draft.parameters[newElementInstance.name] = parameters;

        for (const group of draft.elementGroups) {
          if (isPositionInRect(newElementInstance.Meta, getGroupDimensions(group))) {
            group.elementIds.push(newElementInstance.Id);
            resizeGroup(group, draft.elementInstances, draft.InstancesConnections);

            if (draft.selectedObjectIds.includes(group.id)) {
              // If the group is selected, we also need to select the new element.
              draft.selectedObjectIds.push(newElementInstance.Id);
            }
            break;
          }
        }

        assignElementToStage(elementInstance, draft.stages);
      });
    }
    case 'addPastedObjects': {
      const { elementInstances, parameters, connections, elementGroups } = action.payload;
      return produce(state, draft => {
        const existingConnectionIds = new Set(
          state.InstancesConnections.map(connection => connection.id),
        );

        const allElementInstances = [...draft.elementInstances, ...elementInstances];
        connections
          .filter(
            ({ Source, Target }) =>
              allElementInstances.find(
                instance => instance.name === Source.ElementInstance,
              ) &&
              allElementInstances.find(
                instance => instance.name === Target.ElementInstance,
              ),
          )
          .forEach(connection => {
            if (!existingConnectionIds.has(connection.id)) {
              addConnection(draft, connection);
              existingConnectionIds.add(connection.id);
            }
          });

        elementGroups.forEach(group => {
          draft.elementGroups.push(group);
        });

        elementInstances.forEach(instance => {
          draft.elementInstances.push(instance as Draft<ElementInstance>);
          draft.parameters[instance.name] = parameters[instance.name];
        });

        draft.selectedObjectIds = [
          ...connections.map(c => c.id),
          ...elementInstances.map(ei => ei.Id),
          ...elementGroups.map(g => g.id),
        ];

        assignElementsToStages(elementInstances, draft.stages);
      });
    }
    case 'applyDragDelta':
      return produce(state, draft => {
        const delta = draft.dragDelta;
        const draggedId = draft.draggedObjectId;

        if (!delta || !draggedId) {
          draft.dragError = undefined;
          return;
        }

        // If the applying the drag would cause an invalid stage arrangement, then cancel it.
        if (draft.dragError) {
          resetDragState(draft);
          return;
        }

        const elementsToMove = draft.elementInstances.filter(ei =>
          draft.selectedObjectIds.includes(ei.Id),
        );

        elementsToMove.forEach(ei => {
          ei.Meta.x += delta.x;
          ei.Meta.y += delta.y;
        });

        const groupsToMove = draft.elementGroups.filter(group =>
          draft.selectedObjectIds.includes(group.id),
        );

        groupsToMove.forEach(group => {
          group.Meta.x += delta.x;
          group.Meta.y += delta.y;
        });

        const draggedElement = draft.elementInstances.find(ei => ei.Id === draggedId);

        /**
         * If the user dragged an element (as opposed to a group), then we re-assign all the
         * moved elements into the drop target group, which is the group overlapping with the
         * dragged element. If there is no drop target group, we move the elements onto the canvas.
         */
        if (draggedElement) {
          const dropTarget = getDropTargetGroup(
            draggedElement,
            draft.elementGroups,
            draft.InstancesConnections,
          );

          /**
           * We don't re-assign elements whose current group has also been selected/moved.
           */
          const elementsToReassign = elementsToMove
            .filter(ei => !groupsToMove.some(group => group.elementIds.includes(ei.Id)))
            .map(ei => ei.Id);

          updateElementGroupMembership(
            elementsToReassign,
            dropTarget,
            draft.elementGroups,
            draft.elementInstances,
            draft.InstancesConnections,
          );

          assignElementsToStages(draft.elementInstances, draft.stages, { reset: true });
        }

        resetDragState(draft);
      });

    case 'deleteSelectedObjects':
      return produce(state, draft => {
        if (
          !draft.selectedObjectIds.length ||
          !draft.elementInstances ||
          !draft.parameters
        ) {
          return;
        }

        const namesOfInstancesToDelete = new Set<string>();
        const idsOfInstancesToDelete = new Set<string>();

        for (const instance of draft.elementInstances) {
          if (draft.selectedObjectIds.includes(instance.Id)) {
            namesOfInstancesToDelete.add(instance.name);
            idsOfInstancesToDelete.add(instance.Id);
          }
        }

        draft.elementInstances = draft.elementInstances.filter(
          ({ name }) => !namesOfInstancesToDelete.has(name),
        );

        draft.elementGroups = draft.elementGroups.filter(
          group => !draft.selectedObjectIds.includes(group.id),
        );

        // Remove the deleted element ids from any group they are a member of.
        draft.elementGroups.forEach(group => {
          group.elementIds = group.elementIds.filter(
            id => !idsOfInstancesToDelete.has(id),
          );
        });

        // Remove the deleted elements from their assigned stages
        draft.stages.forEach(stage => {
          stage.elementIds = stage.elementIds.filter(
            id => !idsOfInstancesToDelete.has(id),
          );
        });

        // Remove all DOE factors associated with removed elements
        if (draft.factors) {
          draft.factors = draft.factors.filter(
            factor =>
              !factor.path
                ? true // keep custom factors
                : !namesOfInstancesToDelete.has(factor.path[1]), // check if factor's element hasn't been removed,
          );
        }

        // remove any schema items related to the elements
        if (draft.schema) {
          draft.schema = removeElementsFromSchema(draft.schema, idsOfInstancesToDelete);
        }

        if (draft.factorisedParameters) {
          for (const name of namesOfInstancesToDelete) {
            if (name in draft.factorisedParameters) {
              delete draft.factorisedParameters[name];
            }
          }
        }

        // Explicitly remove the associated entry from the Parameters object, this
        // will prevent instances given the old name from having the parameters from
        // the previous instance with that name.
        namesOfInstancesToDelete.forEach(name => {
          delete draft.parameters[name];
        });

        // Connections are deleted if they themselves were selected for deletion,
        // or if the element instance on either side is being deleted.
        draft.InstancesConnections = draft.InstancesConnections?.filter(connection => {
          const wasSelected = draft.selectedObjectIds.includes(connection.id);
          const sourceDeleted = namesOfInstancesToDelete.has(
            connection.Source.ElementInstance,
          );
          const targetDeleted = namesOfInstancesToDelete.has(
            connection.Target.ElementInstance,
          );

          return !(wasSelected || sourceDeleted || targetDeleted);
        });
      });

    case 'deselectAll':
      return produce(state, draft => {
        draft.selectedObjectIds = [];
        draft.centerElementId = undefined;
        // If elements are deselected, we need to remove any additional panels that
        // are children of the element instance panel.
        if (isAdditionalPanelChildOfElementInstance(draft.additionalPanel)) {
          draft.additionalPanel = undefined;
          resetOutputPreviewState(draft.outputPreviewProps);
        }
      });

    case 'renameElementInstance': {
      const { id, newName } = action.payload;
      return produce(state, draft => {
        const instance = draft.elementInstances.find(ei => ei.Id === id);

        if (!instance) {
          throw new Error(`Cannot rename a non-existent element instance with Id ${id}`);
        }

        const isNewNameSameAsOldName = instance.name === newName;
        if (isNewNameSameAsOldName) {
          return;
        }

        draft.InstancesConnections.forEach(connection => {
          if (connection.Source.ElementInstance === instance.name) {
            connection.Source.ElementInstance = newName;
          } else if (connection.Target.ElementInstance === instance.name) {
            connection.Target.ElementInstance = newName;
          }
        });

        draft.parameters[newName] = draft.parameters[instance.name];
        delete draft.parameters[instance.name];

        if (draft.factors) {
          draft.factors.forEach(factor => {
            if (factor.path && factor.path[1] === instance.name) {
              factor.path[1] = newName;
            }
          });
        }

        if (instance.name in draft.factorisedParameters) {
          draft.factorisedParameters[newName] = draft.factorisedParameters[instance.name];
          delete draft.factorisedParameters[instance.name];
        }

        instance.name = newName;
      });
    }

    case 'resetToWorkflow': {
      const workflowState = action.payload;
      // Keep the selected element set. The element set is fetched by the UI
      // the first time the UI is mounted.

      const preserve = Object.fromEntries(
        (action.preserveKeys ?? []).map(k => [k, state[k]]),
      );

      const requiresDevice = workflowRequiresDevice(workflowState);

      return {
        ...workflowState,
        config: {
          ...workflowState.config,
          global: {
            ...workflowState.config.global,
            requiresDevice,
          },
        },
        // The parameters property used to be optional in the workflow schema, so some
        // workflows were missing them. These were since made required and most workflows
        // had a parameters property added via migrations, but snapshots are not updated,
        // so we take that into account here.
        parameters: workflowState.parameters ?? {},
        stagedParameters: workflowState.stagedParameters ?? {},
        selectedObjectIds: [],
        erroredObjectIds: [],
        simulationNotifications: [],
        labwarePreferenceType: undefined,
        labwarePreferencesAddedOrder: getLabwarePreferencesAddedOrderFromConfig(
          workflowState.config,
        ),
        dragDelta: { x: 0, y: 0 },
        elementGroups: workflowState.elementGroups,
        factors:
          workflowState.factors?.map(factor => {
            return {
              ...factor,
              ...(factor.path
                ? {
                    path: factor.path.map((entry, i) =>
                      i === 0 ? workflowState.workflowName : entry,
                    ) as FactorPath,
                  }
                : {}),
            };
          }) || null,
        ...preserve,
      };
    }

    case 'selectAll':
      return produce(state, draft => {
        draft.selectedObjectIds = [
          ...draft.InstancesConnections.map(({ id }) => id),
          ...draft.elementInstances.map(({ Id }) => Id),
          ...draft.elementGroups.map(({ id }) => id),
        ];
      });

    case 'setConfig': {
      const config = action.payload;
      return { ...state, config: config };
    }

    case 'setConfigToNoDevices': {
      return produce(state, draft => {
        // reset config
        draft.config = castDraft(emptyWorkflowConfig());
        draft.config.global.requiresDevice = false;
        draft.config.configuredDevices = [];
        // close device selector panel
        draft.additionalPanel = undefined;
      });
    }

    case 'saveSelectedDevices': {
      let { configuredDevices } = action.payload;
      const { runConfiguration } = action.payload;

      return produce(state, draft => {
        if (
          configuredDevices &&
          configuredDevices[0]?.deviceId === DATA_ONLY_DUMMY_DEVICE.id
        ) {
          configuredDevices = [];
          draft.config.global.requiresDevice = false;
        } else {
          // Make sure to require devices when device is selected
          draft.config.global.requiresDevice = true;
        }

        const options = runConfiguration?.parsedRunConfig.config.options;
        if (options) {
          const updatedAdvancedOptions: Record<string, ParameterValue> = {};
          options.forEach(option => {
            updatedAdvancedOptions[option.name] = option.defaultValue;
          });
          draft.config.global = {
            ...draft.config.global,
            ...updatedAdvancedOptions,
          };
        }

        const draftConfiguredDevices = configuredDevices?.map(cd => {
          return { ...cd };
        });

        if (draftConfiguredDevices) {
          const selectedStage = draft.stages.find(
            stage => stage.id === draft.selectedStageId,
          );
          // If we are dealing with multi-stages, then devices are specific
          // to each stage, so we must use only those devices related to that
          // stage.
          const prevConfiguredDevices = selectedStage
            ? draft.config.configuredDevices?.filter(device =>
                selectedStage.configuredDevices.includes(device.id),
              )
            : draft.config.configuredDevices;

          if (configuredDevices && prevConfiguredDevices) {
            // if the user's previous device selection had a plate type, we should
            // keep it
            const plateTypes = prevConfiguredDevices.flatMap(
              cd => cd.inputPlateTypes || [],
            );
            const uniquePlateTypes = Array.from(new Set(plateTypes));
            draftConfiguredDevices.forEach(cd => {
              if (hasDeck(cd.type)) {
                cd.inputPlateTypes = uniquePlateTypes;
              }
            });

            // it's the same for tip types
            const tipTypes = prevConfiguredDevices.flatMap(cd => cd.tipTypes ?? []);
            const uniqueTipTypes = Array.from(new Set(tipTypes));
            // except that for tips we filter out any incompatible tips
            // (note that [] is interpreted as 'use all supported tips')
            const allSupportedNames = (
              runConfiguration?.parsedRunConfig.config.supportedTipTypes || []
            ).map(tt => tt.name);
            const supportedTipTypes = uniqueTipTypes.filter(
              tt => tt in allSupportedNames,
            );
            draftConfiguredDevices.forEach(cd => {
              if (hasDeck(cd.type)) {
                cd.tipTypes = supportedTipTypes;
              }
            });
          }

          // apply default layoutPreferences
          draftConfiguredDevices?.forEach(cd => {
            if (hasDeck(cd.type)) {
              cd.layoutPreferences = formatDefaultLayoutOptions(runConfiguration);
            }
          });

          if (selectedStage) {
            // Add in the new configured device, keeping all others that are
            // attached to other stages.
            const previouslyConfiguredDevicesIds =
              prevConfiguredDevices?.map(device => device.id) ?? [];
            const configuredDevicesWithoutPreviousDevice =
              draft.config.configuredDevices?.filter(
                device => !previouslyConfiguredDevicesIds.includes(device.id),
              ) ?? [];

            draft.config.configuredDevices =
              configuredDevicesWithoutPreviousDevice.concat(draftConfiguredDevices);

            // Update the stage to reference the new configured device, and remove
            // the previous one.
            const newlyConfiguredDeviceIds = draftConfiguredDevices.map(
              device => device.id,
            );
            selectedStage.configuredDevices = selectedStage.configuredDevices
              .filter(deviceId => !previouslyConfiguredDevicesIds.includes(deviceId))
              .concat([...newlyConfiguredDeviceIds]);
          } else {
            // If we are not dealing with stages, overwrite the existing configuredDevices.
            draft.config.configuredDevices = castDraft(draftConfiguredDevices);
          }

          setDefaultConfig(draft);

          // Directly navigate user to the deck options panel if a non-dispenser or non-manual device is selected
          if (
            !isEmpty(configuredDevices) &&
            !hasDispenserOrManualDevice(draft.config) &&
            !hasPeripheralDeviceOnly(draft.config)
          ) {
            draft.additionalPanel = 'DeckOptions';
          } else {
            draft.additionalPanel = undefined;
          }
        }
      });
    }

    case `addLabwarePreference`: {
      return addLabwarePreference(state, action.payload);
    }
    case `removeLabwarePreference`: {
      return removeLabwarePreference(state, action.payload);
    }
    case `removeAllLabwareTypePreferences`: {
      return removeAllLabwareTypePreferences(state, action.payload);
    }
    case `insertLabwarePreference`: {
      return insertLabwarePreference(state, action.payload, action.payload.idx);
    }
    case `setLabwarePreferenceType`: {
      return produce(state, draft => {
        draft.labwarePreferenceType = action.payload;
      });
    }
    case 'addAndSetNamedPlate': {
      return addAndSetNamedPlate(state, action.payload);
    }
    case 'removeNamedPlate': {
      return removeNamedPlate(state, action.payload);
    }
    case 'renameNamedPlate': {
      const { oldName, newName } = action.payload;
      return renameNamedPlate(state, oldName, newName);
    }

    case 'setDragDelta':
      return produce(state, draft => {
        setDragDelta(draft, action.payload.id, action.payload.delta);
      });

    case 'nudgeSelectedObjects': {
      const distance = action.payload;
      return produce(state, draft => {
        setDragDelta(draft, draft.selectedObjectIds[0], {
          x: (draft.dragDelta?.x ?? 0) + distance.x,
          y: (draft.dragDelta?.y ?? 0) + distance.y,
        });

        // We track the timestamp of the last nudge operation so the that builder
        // can run an effect which performs a debounced `applyNudge` operation after
        // 500ms. This can't be a boolean called something like `isNudging` as it
        // needs to change after each nudge in order to trigger the effect to re-run.
        draft.lastNudged = Date.now();
      });
    }

    case 'updateParameter': {
      const { parameterName, instanceName, value } = action.payload;

      return produce(state, draft => {
        if (!draft.parameters[instanceName]) {
          draft.parameters[instanceName] = {};
        }
        draft.parameters[instanceName][parameterName] = value;
      });
    }
    case 'updateAllParameters': {
      const { parameterValues, instanceName } = action.payload;

      // Don't save empty maps as parameter values.
      const updatedParameterValues: ParameterValueDict = {};
      Object.entries(parameterValues).forEach(([parameterName, value]) => {
        updatedParameterValues[parameterName] =
          value !== 'object' || !isEmpty(value) ? value : null;
      });

      return produce(state, draft => {
        if (!draft.parameters[instanceName]) {
          draft.parameters[instanceName] = {};
        }
        draft.parameters[instanceName] = updatedParameterValues;
      });
    }
    case 'updatePendingParameter': {
      const { parameterName, instanceName, value } = action.payload;
      return produce(state, draft => {
        if (!draft.stagedParameters[instanceName]) {
          draft.stagedParameters[instanceName] = {};
        }
        draft.stagedParameters[instanceName][parameterName] = value;
      });
    }
    case 'clearStagedParameters': {
      return produce(state, draft => {
        draft.stagedParameters = {};
      });
    }
    case 'setInstanceAnnotation': {
      const { instanceID, annotation } = action.payload;
      return produce(state, draft => {
        const instanceIndex = state.elementInstances.findIndex(
          ({ Id }) => Id === instanceID,
        );
        if (instanceIndex !== -1) {
          draft.elementInstances[instanceIndex].Meta.annotation = annotation;
        }
      });
    }

    case 'setSelectedObjects':
      return produce(state, draft => {
        draft.selectedObjectIds = action.payload;
        draft.centerElementId = undefined;

        if (draft.selectedObjectIds.length === 1) {
          const elementInstance = getElementFromSelection(draft);

          if (isNewAddedElement(elementInstance)) {
            draft.elementInstancePanelTab = ElementDetailsTabs.INPUTS;
          }
        }
      });

    case 'setSelectedDevices':
      return produce(state, draft => {
        const deviceIds = action.payload.map(device => device.id);
        if (draft.stages.length === 1) {
          if (draft.stages[0].configuredDevices.length === 0) {
            draft.stages[0].configuredDevices.push(...deviceIds);
          }
        }
        if (draft.selectedStageId) {
          const stage = draft.stages.find(stage => stage.id === draft.selectedStageId);
          if (stage) {
            // Here we are removing any existing configuredDevices associated with the
            // current stage (these will be replaced with updated configurations as part
            // of the action.payload, which will have e.g. accessible devices included/excluded).
            const devicesFromOtherStages = draft.config.configuredDevices?.filter(
              cp => !stage.configuredDevices.includes(cp.id),
            );
            stage.configuredDevices = [...deviceIds];
            draft.config.configuredDevices = [
              ...(devicesFromOtherStages ?? []),
              ...action.payload,
            ];
            draft.config = castDraft(updateConfigAfterSet(draft.config));
          }
        } else {
          // When first building a workflow we may not have a selected stage and we may create more
          // than one default device. In this case we should always update the initial stage to have
          // matching IDs.
          if (draft.stages.length === 1) {
            draft.stages[0].configuredDevices = deviceIds;
          }
          draft.config.configuredDevices = castDraft(action.payload);
          draft.config = castDraft(updateConfigAfterSet(draft.config));
        }
      });

    case 'setDeviceRunConfig': {
      const { configuredDeviceId, runConfigId, runConfiguration, runConfigVersion } =
        action.payload;
      return produce(state, draft => {
        // reset the config
        setDefaultConfig(draft);

        const configuredDevice = (draft.config.configuredDevices || []).find(
          cd => cd.id === configuredDeviceId,
        );
        if (configuredDevice) {
          configuredDevice.runConfigId = runConfigId;
          configuredDevice.runConfigVersion = runConfigVersion;
          const layoutPrefs = formatDefaultLayoutOptions(runConfiguration);
          configuredDevice.layoutPreferences = castDraft(layoutPrefs);
        }

        draft.labwarePreferencesAddedOrder = getLabwarePreferencesAddedOrderFromConfig(
          draft.config,
        );
      });
    }

    case 'setWorkflowName':
      return produce(state, draft => {
        const newWorkflowName = action.payload;
        draft.workflowName = newWorkflowName;

        draft.factors
          ?.filter(f => f.path)
          .forEach(factor => {
            factor.path![0] = newWorkflowName;
          });
      });

    case 'snapLayoutToOrigin':
      return produce(state, draft => {
        if (
          !arrayIsFalsyOrEmpty(draft.elementInstances) ||
          !arrayIsFalsyOrEmpty(draft.elementGroups) ||
          !arrayIsFalsyOrEmpty(draft.stages)
        ) {
          const normalisedLayout = normaliseLayoutPositions(
            draft.elementInstances,
            draft.elementGroups,
            draft.stages,
          );
          draft.elementInstances = normalisedLayout.elementInstances;
          draft.elementGroups = normalisedLayout.elementGroups;
          draft.stages = normalisedLayout.stages ?? [];
        }
      });

    case 'toggleSelectedObjects': {
      const objectIds = action.payload;
      return produce(state, draft => {
        const selectedObjectSet = new Set(draft.selectedObjectIds);
        for (const id of objectIds) {
          if (selectedObjectSet.has(id)) {
            if (
              canDeselectObject(
                id,
                selectedObjectSet,
                draft.elementInstances,
                draft.elementGroups,
              )
            ) {
              selectedObjectSet.delete(id);
            }
          } else {
            selectedObjectSet.add(id);
          }
        }

        draft.selectedObjectIds = Array.from(selectedObjectSet);
      });
    }

    case 'setErroredObjects': {
      const elementIds = action.payload;
      return produce(state, draft => {
        draft.erroredObjectIds = elementIds;
      });
    }

    /** Handles the highlighting of the selected element instance and the instance panel */
    case 'showErroredElementInstance': {
      return produce(state, draft => {
        const errorElementFromAnthaCore = action.payload?.element;

        let errorElementInstance: ArrayElement<State['elementInstances']> | undefined;
        if (errorElementFromAnthaCore) {
          errorElementInstance = state.elementInstances.find(
            ei => ei.name === errorElementFromAnthaCore.instance_name,
          );
        }

        const resultObjectIds = errorElementInstance ? [errorElementInstance.Id] : [];

        draft.selectedObjectIds = resultObjectIds;
        draft.erroredObjectIds = resultObjectIds;
        /**
         * To showErroredElementInstance the additionalPanel has to be closed as it visually hides the Workspace.
         * Moreover, additionalPanel serves as a parameter editor for some elements.
         * While this event changes focus to an errored element the element that has opened the additionalPanel
         * as parameter editor will most likely be a different element from the errored one.
         * Therefore, having additionalPanel opened will most likely result in an error when this event is dispatched.
         */
        draft.additionalPanel = undefined;
      });
    }
    case 'setActivePanel': {
      return produce(state, draft => {
        draft.activePanel = action.payload;
        // Don't ever close additional panels that are source from element instance.
        if (!isAdditionalPanelChildOfElementInstance(draft.additionalPanel)) {
          draft.additionalPanel = undefined;
        }
        // If the 'WorkflowSettings' panel is triggered in multi-stage, we have to ensure
        // that a stage is selected, to populate the settings.
        if (
          action.payload === 'WorkflowSettings' &&
          draft.stages.length > 0 &&
          !draft.selectedStageId
        ) {
          draft.selectedStageId = draft.stages[0].id;
        }
        draft.factorEditing = {
          selectedFactorElement: undefined,
          selectedFactorParameter: undefined,
        };
      });
    }
    case 'setAdditionalPanel': {
      return produce(state, draft => {
        draft.additionalPanel = action.payload;
      });
    }
    case 'createElementGroupFromSelection': {
      return produce(state, draft => {
        const {
          elementInstances,
          InstancesConnections: connections,
          selectedObjectIds,
        } = state;

        const selectedElements = getSelectedElementsForGroup(
          elementInstances,
          selectedObjectIds,
        );

        /**
         * If no elements selected cmd+G should do nothing.
         */
        if (selectedElements.length === 0) return;

        const newGroup = createElementGroup(
          selectedElements,
          // Passing draft.elementGroups here so that they can be updated with element placement across groups
          draft.elementGroups,
          elementInstances,
          connections,
        );

        draft.elementGroups.push(newGroup);
      });
    }
    case 'createElementGroupFromMouseArea': {
      const selectedArea = action.payload;
      return produce(state, draft => {
        const {
          elementInstances,
          InstancesConnections: connections,
          selectedObjectIds,
        } = state;

        const selectedElements = getSelectedElementsForGroup(
          elementInstances,
          selectedObjectIds,
        );

        let newGroup: Group | null;

        if (selectedElements.length === 0) {
          newGroup = createEmptyElementGroup(selectedArea, draft.elementGroups);
        } else {
          newGroup = createElementGroup(
            selectedElements,
            draft.elementGroups,
            elementInstances,
            connections,
          );
        }

        if (newGroup) {
          draft.elementGroups.push(newGroup);
          /**
           * Unselect all elements after group has been created
           */
          draft.selectedObjectIds = [];
        }
      });
    }
    case 'deleteElementGroup': {
      const id = action.payload;
      return produce(state, draft => {
        const idx = draft.elementGroups.findIndex(group => group.id === id);
        draft.elementGroups.splice(idx, 1);
      });
    }
    case 'updateElementGroup': {
      const { id: updatedGroupId, ...groupUpdates } = action.payload;
      return produce(state, draft => {
        const updatedGroupIdx = draft.elementGroups.findIndex(
          group => group.id === updatedGroupId,
        );
        draft.elementGroups[updatedGroupIdx] = {
          ...draft.elementGroups[updatedGroupIdx],
          ...groupUpdates,
        };
      });
    }
    case 'setElementGroupDescription': {
      const { id, description } = action.payload;
      return produce(state, draft => {
        const group = draft.elementGroups.find(g => g.id === id);

        if (group) {
          group.Meta.description = description;
        }
      });
    }
    case 'setVisibleCanvasArea': {
      return {
        ...state,
        visibleCanvasArea: action.payload,
      };
    }
    case 'resizeElementGroupToFit': {
      return produce(state, draft => {
        const group = draft.elementGroups.find(group => group.id === action.payload);

        if (group) {
          resizeGroup(group, draft.elementInstances, draft.InstancesConnections, true);
        }
      });
    }
    case 'setElementInstanceSize': {
      return produce(state, draft => {
        const { elementId, ...measurements } = action.payload;

        const element = draft.elementInstances.find(ei => ei.Id === elementId);

        if (element) {
          element.Meta = {
            ...element.Meta,
            ...measurements,
          };
        }

        const group = draft.elementGroups.find(group =>
          group.elementIds.includes(elementId),
        );

        if (group) {
          /**
           * If the element is part of a group, we resize the group to ensure it fits the new size.
           *
           * TODO: If multiple elements in a group set their size at the same time, such as when
           * a workflow loads or things are pasted into the builder, then we might end up doing
           * redundant resize work. We could avoid this by deferring the resize operations
           * until after the state has processed all the set actions. Something like redux-toolkit's
           * [createListenerMiddleware](https://redux-toolkit.js.org/api/createListenerMiddleware)
           * would be useful for this.
           */
          resizeGroup(group, draft.elementInstances, draft.InstancesConnections);
        }
      });
    }
    case 'updateElementsWithContexts': {
      const elementContextMap = action.payload;
      return produce(state, draft => {
        draft.elementContextError = null;

        for (const ei of draft.elementInstances) {
          const elementContext = elementContextMap[ei.Id];

          if (elementContext) {
            ei.Meta = {
              ...ei.Meta,
              ...elementContext,
            };
          }
        }
      });
    }
    case 'centerToElement': {
      const { elementId, selectElement } = action.payload;
      return produce(state, draft => {
        // Nothing to center
        if (!state.visibleCanvasArea) return;

        // Close any additional panel as it hides the focused element
        draft.additionalPanel = undefined;

        if (selectElement) {
          // Select only focused element
          draft.selectedObjectIds = [elementId];
        }

        for (const ei of draft.elementInstances) {
          if (ei.Id === elementId) {
            draft.centerElementId = ei.Id;

            const x = ei.Meta.x;
            const y = ei.Meta.y;
            const width = ei.Meta.bodySize?.width ?? 0;
            const height = ei.Meta.bodySize?.height ?? 0;

            const left =
              state.visibleCanvasArea.left +
              state.visibleCanvasArea.width / 2 -
              (x + width / 2);
            const top =
              state.visibleCanvasArea.top +
              state.visibleCanvasArea.height / 2 -
              (y + height / 2);

            draft.centerArea = { left, top, width, height };
            break;
          }
        }
      });
    }
    case 'updateFactors': {
      return produce(state, draft => {
        if (!draft.factors) {
          draft.factors = [];
        }

        let replaceIndex =
          draft.factors?.findIndex(f => action.payload.factorsToRemove?.includes(f.id)) ??
          -1;

        if (action.payload.factorsToRemove && action.payload.factorsToRemove.length > 0) {
          draft.factors = draft.factors.filter(
            f => !action.payload.factorsToRemove?.includes(f.id),
          );
        }

        if (action.payload.factorsToAdd) {
          action.payload.factorsToAdd.forEach(factor => {
            const existingIndex = draft.factors?.findIndex(f => f.id === factor.id);
            const clone = {
              ...factor,
              values: [...factor.values],
            };

            if (existingIndex != null && existingIndex >= 0) {
              draft.factors?.splice(existingIndex, 1, clone);
            } else if (replaceIndex >= 0) {
              draft.factors?.splice(replaceIndex, 0, clone);
              replaceIndex += 1;
            } else {
              draft.factors?.push(clone);
            }
          });
        }
      });
    }
    case 'toggleFactorEditing': {
      const { selectedFactorElement, selectedFactorParameter } = action.payload;
      return produce(state, draft => {
        draft.activePanel = selectedFactorParameter ? 'DOEBuilder' : undefined;
        draft.factorEditing = {
          selectedFactorElement,
          selectedFactorParameter,
        };
      });
    }
    case 'toggleFactor': {
      const { elementName, parameterName, factorised } = action.payload;
      return produce(state, draft => {
        if (!state.factorisedParameters[elementName]) {
          draft.factorisedParameters[elementName] = {};
        }
        draft.factorisedParameters[elementName][parameterName] = factorised;
        draft.factors = !draft.factors
          ? null
          : draft.factors.map(factor =>
              factor.path?.[1] === elementName && factor.path?.[2] === parameterName
                ? { ...factor, included: factorised }
                : factor,
            );
      });
    }
    case 'switchMode': {
      return produce(state, draft => {
        draft.mode = action.payload;
        /**
         * Switching mode should close all opened panels making it impossible
         * to see the DOE Design panel in Build mode for example.
         */
        draft.activePanel = undefined;
        draft.additionalPanel = undefined;
        /**
         * We deselect all selected objects, unless there is only one element selected
         * and it is DOE-able. This lets the user quickly switch between the DOE/non-DOE
         * instance panels.
         */
        if (draft.selectedObjectIds.length > 1) {
          draft.selectedObjectIds = [];
        } else {
          const selectedElement = draft.elementInstances.find(
            el => el.Id === draft.selectedObjectIds[0],
          );

          if (
            !selectedElement?.element.inputs.some(input => input.configuration?.isDOEable)
          ) {
            draft.selectedObjectIds = [];
          }
        }
      });
    }
    case 'setElementValidationVisible': {
      return produce(state, draft => {
        draft.switchElementParameterValidation = action.payload;
      });
    }
    case 'setElementContextError': {
      return produce(state, draft => {
        draft.elementContextError = action.payload;
        // Clear the element context, as it is no longer valid, and could mislead the user
        // into thinking a stale error is the cause of the problem.
        draft.elementInstances.forEach(element => {
          element.Meta.errors = undefined;
          element.Meta.status = undefined;
          element.Meta.outputs = undefined;
        });
      });
    }
    case 'setIsSaving': {
      return produce(state, draft => {
        draft.isSaving = action.payload;
      });
    }
    case 'setSelectedStageId': {
      return produce(state, draft => {
        draft.selectedStageId = action.payload.id;

        if (action.payload.id === undefined && draft.activePanel === 'WorkflowSettings') {
          draft.activePanel = undefined;
        }

        // Re-center the canvas view if the selection has occured through a click in the timeline.
        if (action.payload.id !== undefined && action.payload.isThroughTimeline) {
          const index = draft.stages.findIndex(stage => stage.id === action.payload.id);
          const stage = draft.stages[index];

          const nextStageX = draft.stages[1]?.meta.x;

          // If centering on the first stage, there is no initial x-coordinate, so we use whatever's
          // lowest: The x-coord of the leftmost element, or the next stage's x-coord with a 300px gap
          // (in case the first stage is empty)
          const left =
            index > 0
              ? (stage.meta.x ?? 0) + BUILDER_CONTROLS_PADDING_LEFT_RIGHT * 2 // This corrects for the padding around the viewable area
              : draft.elementInstances.length > 0
              ? Math.min(...draft.elementInstances.map(ei => ei.Meta.x))
              : nextStageX === undefined
              ? 0
              : nextStageX - 300;
          const width = draft.visibleCanvasArea?.width;

          draft.centerArea = {
            top: 0,
            left,
            width,
          };
        }
      });
    }
    case 'applyStageDragDelta': {
      return produce(state, draft => {
        if (!draft.dragError && draft.selectedStageId && draft.stageDragDelta) {
          const stageIndex = draft.stages.findIndex(
            stage => stage.id === draft.selectedStageId,
          );
          const stage = draft.stages[stageIndex];

          // This shouldn't happen, but just in case it does.
          if (stage.meta.x === undefined) {
            stage.meta.x = draft.stages[stageIndex].meta.x ?? 0;
          }

          if (stage?.meta.x !== undefined) {
            stage.meta.x += draft.stageDragDelta;
          }

          assignElementsToStages(draft.elementInstances, draft.stages, { reset: true });
        }

        resetDragState(draft);
      });
    }
    case 'setStageDragDelta': {
      return produce(state, draft => {
        draft.stageDragDelta = action.payload;

        const selectedStage = draft.stages.find(
          stage => stage.id === draft.selectedStageId,
        );

        if (selectedStage) {
          const updatedStages = produce(state.stages, stages => {
            stages.forEach(stage => {
              if (stage.id === draft.selectedStageId && stage?.meta.x !== undefined) {
                stage.meta.x += draft.stageDragDelta;
              }
            });
          });

          checkDragValidity(draft, undefined, updatedStages);
        }
      });
    }
    case 'updateStageName': {
      return produce(state, draft => {
        const stage = draft.stages.find(stage => stage.id === action.payload.stageId);
        if (stage) {
          stage.name = action.payload.newName;
        }
      });
    }
    case 'addNewStage': {
      return produce(state, draft => {
        const lastStage = draft.stages[draft.stages.length - 1];
        const position =
          Math.max(
            (lastStage.meta.x ?? 0) + 320,
            ...draft.elementInstances.map(el => el.Meta.x),
          ) +
          ELEMENT_INSTANCE_WIDTH +
          16;

        const lastStageConfiguredDevicesWithUpdatedId =
          draft.config.configuredDevices
            ?.filter(cd => lastStage.configuredDevices.includes(cd.id))
            .map(cd => {
              return {
                ...cd,
                id: uuid() as ConfiguredDeviceId,
              };
            }) ?? [];

        draft.config.configuredDevices = draft.config.configuredDevices
          ? [
              ...draft.config.configuredDevices,
              ...lastStageConfiguredDevicesWithUpdatedId,
            ]
          : [...lastStageConfiguredDevicesWithUpdatedId];

        draft.stages.push({
          configuredDevices: lastStageConfiguredDevicesWithUpdatedId.map(cd => cd.id),
          elementIds: [],
          id: uuid(),
          meta: {
            x: position,
          },
          name: 'New Stage',
        });

        draft.centerArea = {
          top: 0,
          left: position,
        };
      });
    }
    case 'deleteStage': {
      return produce(state, draft => {
        const stageToDelete = draft.stages.findIndex(
          stage => stage.id === action.payload,
        );

        if (stageToDelete >= 0) {
          const isLastStage = stageToDelete === draft.stages.length - 1;

          const [stage] = draft.stages.splice(stageToDelete, 1);

          const replacementStage =
            draft.stages[stageToDelete] ?? draft.stages[stageToDelete - 1];

          if (replacementStage) {
            replacementStage.elementIds.push(...stage.elementIds);

            if (!isLastStage && draft.stages.length > 1) {
              replacementStage.meta.x = stage.meta.x;
            }
          }

          draft.config.configuredDevices = draft.config.configuredDevices?.filter(
            cd => !stage.configuredDevices.includes(cd.id),
          );
        }

        if (draft.selectedStageId === action.payload) {
          draft.selectedStageId = undefined;

          if (draft.activePanel === 'WorkflowSettings') {
            draft.activePanel = undefined;
          }
        }
      });
    }
    case 'applyNudge': {
      return produce(state, draft => {
        if (!draft.dragError) {
          if (draft.lastNudged && draft.dragDelta) {
            const { x, y } = draft.dragDelta;

            const movedGroups = draft.elementGroups.filter(group =>
              draft.selectedObjectIds.includes(group.id),
            );

            movedGroups.forEach(group => {
              group.Meta.x += x;
              group.Meta.y += y;
            });

            draft.elementInstances.forEach(ei => {
              if (draft.selectedObjectIds.includes(ei.Id)) {
                ei.Meta.x += x;
                ei.Meta.y += y;
              }

              // If an element's group has also moved, skipping checking to reassign it.
              if (!movedGroups.some(group => group.elementIds.includes(ei.Id))) {
                const group = getDropTargetGroup(
                  ei,
                  draft.elementGroups,
                  draft.InstancesConnections,
                );

                updateElementGroupMembership(
                  [ei.Id],
                  group,
                  draft.elementGroups,
                  draft.elementInstances,
                  draft.InstancesConnections,
                );
              }
            });
          }

          assignElementsToStages(draft.elementInstances, draft.stages, { reset: true });
        }

        resetDragState(draft);
      });
    }
    case 'openOutputPreview': {
      return produce(state, draft => {
        // We have to add element to selectedObjectIds when the panel is opened
        // to allow useElementContext() to function.
        // It as well triggers the opening of side panel sice side panel
        // observes selectedObjectIds
        if (action.payload.selectedElementId) {
          draft.selectedObjectIds = [action.payload.selectedElementId];
        }
        if (draft.elementInstancePanelTab !== ElementDetailsTabs.OUTPUTS) {
          draft.elementInstancePanelTab = ElementDetailsTabs.OUTPUTS;
        }
        draft.additionalPanel = 'OutputPreview';
        draft.outputPreviewProps = {
          instanceId: action.payload.selectedElementId,
          selectedOutputParameterName: action.payload.selectedOutputParameterName,
          selectedPlateName: state.outputPreviewProps.selectedPlateName,
          outputType: action.payload.outputType,
          entityView: action.payload.entityView,
        };
      });
    }
    case 'setOutputPreviewEntityView': {
      return produce(state, draft => {
        draft.outputPreviewProps.entityView = action.payload;
      });
    }
    case 'selectPlateInOutputPreview': {
      return produce(state, draft => {
        draft.outputPreviewProps.selectedPlateName = action.payload;
      });
    }
    case 'closeOutputPreview': {
      return produce(state, draft => {
        draft.additionalPanel = undefined;
        resetOutputPreviewState(draft.outputPreviewProps);
      });
    }
    case 'setElementInstancePanelTab': {
      return produce(state, draft => {
        draft.elementInstancePanelTab = action.payload;
        if (action.payload === ElementDetailsTabs.INPUTS) {
          resetOutputPreviewState(draft.outputPreviewProps);
        }
      });
    }
    case 'fixDevices': {
      return produce(state, draft => {
        // See https://synthace.atlassian.net/browse/SYN-9099. Some devices don't exist in the
        // configured devices which means that it was also incorrectly added to a stage and now
        // doesn't exist anymore. Some devices could also be created by an old bug in which a
        // manual devices where not correctly added to the default stage.
        draft.config.configuredDevices = draft.config.configuredDevices?.filter(device =>
          draft.stages.some(stage => stage.configuredDevices.some(v => v === device.id)),
        );
        draft.stages = draft.stages.map(stage => ({
          ...stage,
          configuredDevices: stage.configuredDevices.filter(v =>
            draft.config.configuredDevices?.some(device => v === device.id),
          ),
        }));
      });
    }
  }
}

export type State = {
  config: WorkflowConfig;
  readonly parentWorkflowID: WorkflowId | null;
  readonly elementSet: GraphQLElementSet | null;
  readonly template?: TemplateWorkflow;
  readonly parameters: BundleParameters;
  /**
   * For complex parameter editors (i.e. the table editor) where we don't want
   * to "permanently" flush updates to the state until the user has clicked
   * save or otherwise indicated they're done. We still want to be able to
   * provide their values to the Autocomplete context temporarily so any
   * autocomplete facilities within the editor can work with the new values.
   * This is where they go and it should be flushed after the user is done -
   * whether they save or cancel.
   */
  readonly stagedParameters: BundleParameters;
  readonly elementInstances: ElementInstance[];
  readonly InstancesConnections: Identifiable<Connection>[];
  readonly workflowName: string;
  readonly editMode: WorkflowEditMode;
  readonly source: EditorType;
  readonly authorName: string;
  readonly selectedObjectIds: string[];
  readonly dragDelta: Position2d | null;
  readonly draggedObjectId: string | null;
  /** Keeps track of the list of simulations that have been started. */
  readonly simulationNotifications: readonly SimulationNotificationDetails[];
  readonly erroredObjectIds: string[];
  readonly activePanel: PanelContent;
  readonly additionalPanel: AdditionalPanelContent;
  readonly labwarePreferenceType: LabwareType | undefined;
  readonly labwarePreferencesAddedOrder: Partial<{
    [position: string]: Set<LabwareType>;
  }>;
  readonly plateEditorPanelProps: {
    plateNameIndex: number | undefined;
    plateName: string | undefined;
    instanceName: string | undefined;
  };
  readonly elementGroups: Group[];
  readonly stages: Stage[];
  readonly stageDragDelta: number;
  readonly selectedStageId: string | undefined;
  // The area of the canvas that is visible (not covered by side panels).
  readonly visibleCanvasArea: Dimensions | undefined;
  readonly centerArea: Partial<Dimensions> | undefined;
  /**
   * This is the ID of the focused element which is centred on the Workspace
   * after clicking its element validation error notification.
   */
  readonly centerElementId: string | undefined;
  readonly factors: Factors | null;
  readonly factorEditing: {
    selectedFactorElement: string | undefined;
    selectedFactorParameter: string | undefined;
  };
  readonly factorisedParameters: BundleParameters;
  readonly mode: WorkflowBuilderMode;
  readonly contentSource: ContentType;
  readonly switchElementParameterValidation: boolean;
  readonly elementContextError: CoreError | null;
  readonly isSaving: boolean;
  readonly dragError: string | undefined;
  readonly lastNudged: number | undefined;
  readonly outputPreviewProps: OutputPreviewState;
  readonly elementInstancePanelTab: ElementDetailsTabs;
  readonly schema: Schema | undefined;
};

const EMPTY_INIT_STATE: Partial<State> = {};

function getInitialState(initialState: Partial<State> = EMPTY_INIT_STATE): State {
  return {
    parentWorkflowID: null,
    config: emptyWorkflowConfig(),
    InstancesConnections: [],
    elementInstances: [],
    parameters: {},
    stagedParameters: {},
    workflowName: '', // When loading, show no name
    editMode: WorkflowEditMode.ENABLED_LATEST_OWNED_BY_ME,
    source: EditorType.WORKFLOW_EDITOR,
    authorName: '',
    elementSet: null,
    selectedObjectIds: [],
    dragDelta: null,
    draggedObjectId: null,
    simulationNotifications: [],
    erroredObjectIds: [],
    labwarePreferenceType: undefined,
    activePanel: undefined,
    additionalPanel: undefined,
    labwarePreferencesAddedOrder: {},
    plateEditorPanelProps: {
      plateNameIndex: undefined,
      plateName: undefined,
      instanceName: undefined,
    },
    elementGroups: [],
    stages: [],
    selectedStageId: undefined,
    stageDragDelta: 0,
    visibleCanvasArea: undefined,
    centerArea: undefined,
    centerElementId: undefined,
    factors: null,
    factorEditing: {
      selectedFactorElement: undefined,
      selectedFactorParameter: undefined,
    },
    factorisedParameters: {},
    mode: 'Build',
    contentSource: ContentType.USER_GENERATED,
    switchElementParameterValidation: true,
    elementContextError: null,
    isSaving: false,
    dragError: undefined,
    lastNudged: undefined,
    outputPreviewProps: {
      selectedOutputParameterName: undefined,
      selectedPlateName: undefined,
      outputType: undefined,
      entityView: 'plate',
    },
    elementInstancePanelTab: ElementDetailsTabs.INPUTS,
    schema: undefined,
    ...initialState,
  };
}

function addConnection(state: State, connection: Connection) {
  const { Source, Target } = connection;
  state.InstancesConnections.push(cloneWithUUID(connection));
  delete state.parameters[Source.ElementInstance]?.[Source.ParameterName];
  delete state.parameters[Target.ElementInstance]?.[Target.ParameterName];
}

type WorkflowBuilderActionType = WorkflowBuilderAction['type'];

const isUndoEnabledByActionType: {
  [k in WorkflowBuilderActionType]: boolean;
} = {
  addConnection: true,
  addElementInstance: true,
  addPastedObjects: true,
  applyDragDelta: false,
  deleteSelectedObjects: true,
  deselectAll: true,
  renameElementInstance: true,
  resetToWorkflow: false,
  selectAll: true,
  setConfig: true,
  setConfigToNoDevices: true,
  setLabwarePreferenceType: true,
  addLabwarePreference: true,
  removeLabwarePreference: true,
  removeAllLabwareTypePreferences: true,
  insertLabwarePreference: true,
  addAndSetNamedPlate: true,
  removeNamedPlate: true,
  renameNamedPlate: true,
  setDragDelta: false,
  nudgeSelectedObjects: true,
  updateParameter: true,
  updateAllParameters: true,
  setInstanceAnnotation: true,
  setSelectedObjects: true,
  setSelectedDevices: true,
  setDeviceRunConfig: false,
  setWorkflowName: true,
  snapLayoutToOrigin: false,
  toggleSelectedObjects: true,
  setErroredObjects: false,
  showErroredElementInstance: false,
  setActivePanel: false,
  setAdditionalPanel: false,
  saveSelectedDevices: true,
  updatePendingParameter: false,
  clearStagedParameters: false,
  createElementGroupFromSelection: true,
  createElementGroupFromMouseArea: true,
  deleteElementGroup: true,
  updateElementGroup: true,
  setElementGroupDescription: true,
  resizeElementGroupToFit: true,
  setVisibleCanvasArea: false,
  setElementInstanceSize: false,
  updateElementsWithContexts: false,
  centerToElement: false,
  toggleFactorEditing: true,
  toggleFactor: true,
  switchMode: false,
  updateFactors: true,
  setElementValidationVisible: true,
  setElementContextError: false,
  setIsSaving: false,
  setSelectedStageId: false,
  setStageDragDelta: false,
  applyStageDragDelta: true,
  updateStageName: true,
  addNewStage: true,
  deleteStage: true,
  applyNudge: true,
  openOutputPreview: false,
  setOutputPreviewEntityView: false,
  selectPlateInOutputPreview: false,
  closeOutputPreview: false,
  setElementInstancePanelTab: false,
  fixDevices: false,
};

function isUndoable(action: WorkflowBuilderAction) {
  return isUndoEnabledByActionType[action.type];
}

const useWorkflowBuilderReducer = (initialState?: Partial<State>) =>
  useUndoReducer<State, WorkflowBuilderAction>(
    workflowBuilderReducer,
    getInitialState(initialState),
    isUndoable,
  );

export function useWorkflowBuilderDispatch() {
  return useContextSelector(WorkflowBuilderStateContext, ([_, dispatch]) => dispatch);
}

export function useWorkflowBuilderSelector<TSelected = unknown>(
  selector: (state: State) => TSelected,
): TSelected {
  const stateSelector = ([state, _]: ReturnType<typeof useWorkflowBuilderReducer>) =>
    selector(state.current);
  return useContextSelector(WorkflowBuilderStateContext, stateSelector);
}

export default function WorkflowBuilderStateContextProvider({
  children,
  initialState,
}: PropsWithChildren<{ initialState?: Partial<State> }>) {
  const value = useWorkflowBuilderReducer(initialState);
  return (
    <WorkflowBuilderStateContext.Provider value={value}>
      {children}
    </WorkflowBuilderStateContext.Provider>
  );
}

const WorkflowBuilderStateContext = createContext<
  ReturnType<typeof useWorkflowBuilderReducer>
>([
  {
    undo: [],
    current: getInitialState(),
    redo: [],
  },
  () => {},
]);
