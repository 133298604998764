import React, { useCallback, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import InputIcon from '@mui/icons-material/Input';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { ParameterEntrySelector } from 'client/app/apps/protocols/ProtocolInstancePanel/ParameterEntrySelector';
import ElementParameterFactorSwitch from 'client/app/components/Parameters/ElementParameterFactorSwitch';
import { getParameterDisplayName } from 'client/app/lib/workflow/elementConfigUtils';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { Parameter } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import Colors from 'common/ui/Colors';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = {
  elementId: string;
  parameter: Parameter;
  /** keys within parameter that are enabled as protocol inputs */
  enabledKeys?: string[];
  value: any;
  checked: boolean;
  isDisabled: boolean;
  otherStepMembership?: string;
  onToggle: (
    checked: boolean,
    editor: ParameterEditorConfigurationSpec,
    value: any,
    key?: string | number,
  ) => void;
};

export function ProtocolItem({
  elementId,
  parameter,
  enabledKeys = [],
  value,
  checked,
  isDisabled,
  otherStepMembership,
  onToggle,
}: Props) {
  // by this point we should only have parameters with configurations
  const editor = parameter.configuration!.editor;
  const hasValue = value !== undefined;
  const [viewValue, setViewValue] = useState(false);

  const isDebugModeEnabled = useFeatureToggle('ELEMENT_CONFIGURATION_DEBUG_MODE');
  const displayName = getParameterDisplayName(parameter, isDebugModeEnabled);

  const handleParameterToggle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isNowChecked = e.target.checked;
      onToggle(isNowChecked, editor, value);
      if (isNowChecked === false) {
        // disabling the entire parameter through this toggle should also
        // disable any keys set. note: since we are removing inputs, it's ok
        // that the editor is not the value editor. It's a reasonable amount of
        // (unnecessary) work to get the correct value editor
        enabledKeys.map(key => onToggle(false, editor, null, key));
      }
    },
    [editor, enabledKeys, onToggle, value],
  );

  const handleParameterEntryToggle = useCallback(
    (
      keyChecked: boolean,
      valueEditor: ParameterEditorConfigurationSpec,
      valueForKey: any,
      key: string | number,
    ) => {
      onToggle(keyChecked, valueEditor, valueForKey, key);
      if (keyChecked) {
        // disable editing the entire parameter
        onToggle(false, parameter.configuration!.editor, null);
      }
    },
    [onToggle, parameter.configuration],
  );

  return (
    <div>
      <ListItem
        disableGutters
        secondaryAction={
          <ElementParameterFactorSwitch
            checked={checked}
            onChange={handleParameterToggle}
            disabled={isDisabled}
            label={<StyledInputIcon checked={checked} />}
          />
        }
      >
        {hasValue ? (
          <StyledListItemButton
            onClick={() => setViewValue(prev => !prev)}
            disableGutters
          >
            <ExpandIcon isExpanded={viewValue} />
            <Typography sx={{ fontSize: '12px' }}>{displayName}</Typography>
          </StyledListItemButton>
        ) : (
          <ListItem disableGutters>
            <Tooltip title="This parameter has no values to see">
              <HorizontalRuleIcon fontSize="small" color="disabled" />
            </Tooltip>
            <Typography sx={{ fontSize: '12px' }}>{displayName}</Typography>
          </ListItem>
        )}
      </ListItem>
      {otherStepMembership && (
        <HelperText>{`Selected in ${otherStepMembership}`}</HelperText>
      )}
      <Collapse in={viewValue}>
        <ParameterEntrySelector
          elementInstanceId={elementId}
          editor={editor}
          value={value}
          enabledKeys={enabledKeys}
          isDisabled={isDisabled}
          onToggle={handleParameterEntryToggle}
        />
      </Collapse>
      <SpacingDivider />
    </div>
  );
}

function ExpandIcon({ isExpanded }: { isExpanded: boolean }) {
  const size = 'small';
  return isExpanded ? <ExpandLess fontSize={size} /> : <ExpandMore fontSize={size} />;
}

type StyledInputIconProps = {
  checked?: boolean;
};

const StyledInputIcon = styled(InputIcon, {
  shouldForwardProp: prop => prop !== 'disabled',
})<StyledInputIconProps>(({ checked }) => ({
  color: checked ? Colors.BACKGROUND : Colors.TEXT_PRIMARY,
}));

const HelperText = styled(TypographyWithTooltip)(({ theme }) => ({
  ...theme.typography.caption,
  padding: theme.spacing(3, 4),
  marginBottom: theme.spacing(3),
  height: '32px',
  width: '100%',
  background: Colors.GREY_10,
  borderRadius: theme.spacing(2),
}));

const StyledListItemButton = styled(ListItemButton)({
  '&:hover': { background: 'none' },
});

const SpacingDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
