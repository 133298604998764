import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';

import { useDeleteProtocolsWithId } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { ProtocolDetailsDialog } from 'client/app/apps/protocols/dialogs/ProtocolDetailsDialog';
import UpdateProtocolDialog from 'client/app/apps/protocols/dialogs/UpdateProtocolDialog';
import { Protocol } from 'client/app/gql';
import { protocolsRoutes } from 'client/app/lib/nav/actions';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import useDialog from 'common/ui/hooks/useDialog';

type ProtocolListActionContextType = {
  handleOpenDialog: (protocol: Protocol) => void;
  handleEditProtocol: (id: ProtocolId, version: ProtocolVersion) => void;
  handleDuplicateProtocol: (id: ProtocolId, version: ProtocolVersion) => void;
  handleDeleteProtocolWithConfirmation: (id: ProtocolId) => void;
  handleOpenUpdateProtocolDialog: (id: ProtocolId, version: ProtocolVersion) => void;
};

export const ProtocolListActionContext = createContext<
  ProtocolListActionContextType | undefined
>(undefined);

type ProtocolListActionProviderProps = {} & PropsWithChildren;

export const useProtocolListActionContext = () => {
  const context = useContext(ProtocolListActionContext);

  if (context === undefined) {
    throw new Error(
      'useProtocolListActionContext ust be used within a ProtocolListActionProvider',
    );
  }

  return context;
};

export const ProtocolListActionProvider: FC<ProtocolListActionProviderProps> = ({
  children,
}) => {
  const { navigate } = useNavigation();
  const { handleDeleteProtocolsWithId } = useDeleteProtocolsWithId();

  const [protocolDetailsDialog, openProtocolDetailsDialog] =
    useDialog(ProtocolDetailsDialog);
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const [updateProtocolDialog, openUpdateProtocolDialog] =
    useDialog(UpdateProtocolDialog);

  const handleOpenDialog = useCallback(
    async (protocol: Protocol) => {
      await openProtocolDetailsDialog({ id: protocol.id, version: protocol.version });
    },
    [openProtocolDetailsDialog],
  );

  const handleEditProtocol = useCallback(
    (id: ProtocolId, version: ProtocolVersion) => {
      navigate(protocolsRoutes.editProtocol, {
        id,
        version,
      });
    },
    [navigate],
  );

  const handleDuplicateProtocol = useCallback(
    (id: ProtocolId, version: ProtocolVersion) => {
      navigate(protocolsRoutes.copyProtocol, {
        id: id,
        version: version,
      });
    },
    [navigate],
  );

  const handleDeleteProtocolWithConfirmation = useCallback(
    async (id: ProtocolId) => {
      const isDeleteConfirmed = await openConfirmationDialog({
        action: 'delete',
        isActionDestructive: true,
        object: 'protocol',
      });

      if (isDeleteConfirmed) {
        await handleDeleteProtocolsWithId(id);
      }
    },
    [handleDeleteProtocolsWithId, openConfirmationDialog],
  );

  const handleOpenUpdateProtocolDialog = async (
    id: ProtocolId,
    version: ProtocolVersion,
  ) => {
    await openUpdateProtocolDialog({ id, version });
  };

  const state = {
    handleOpenDialog,
    handleEditProtocol,
    handleDuplicateProtocol,
    handleDeleteProtocolWithConfirmation,
    handleOpenUpdateProtocolDialog,
  };

  return (
    <ProtocolListActionContext.Provider value={state}>
      {children}
      {confirmationDialog}
      {protocolDetailsDialog}
      {updateProtocolDialog}
    </ProtocolListActionContext.Provider>
  );
};
