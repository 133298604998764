import React, { useRef } from 'react';

import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import PlateDescriptionEditor, {
  ExtraPlateDescriptionProps,
} from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/PlateDescriptionEditor';
import { PlateSetDescription } from 'common/types/plateSetDescription';
import Colors from 'common/ui/Colors';

type Props = {
  value: PlateSetDescription;
  onChange: (value: PlateSetDescription | null) => void;
  onClose: () => void;
  extraProps: ExtraPlateDescriptionProps;
  isDisabled: boolean;
};

export default function PlateDescriptionEditorPanel({
  value,
  onChange,
  onClose,
  extraProps,
  isDisabled,
}: Props) {
  const panelRef = useRef<HTMLDivElement>(null);

  return (
    <Grow in unmountOnExit>
      <Panel ref={panelRef} elevation={4}>
        <PlateDescriptionEditor
          value={value}
          onChange={onChange}
          isDisabled={isDisabled}
          extraProps={extraProps}
          onClose={onClose}
          containerRef={panelRef}
        />
      </Panel>
    </Grow>
  );
}

const Panel = styled(Paper)({
  display: 'grid',
  gridTemplate: `
    'titlebar' auto
    'main' minmax(0, 1fr)
  `,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: Colors.WHITE,
  borderRadius: '8px',
});
