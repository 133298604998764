import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { StepParamConfigById } from 'client/app/apps/protocols/context/StepsProvider/stepsConfig';
import { CreateOutputStepState } from 'client/app/apps/protocols/context/StepsProvider/stepState';
import ProtocolInstancePanel from 'client/app/apps/protocols/ProtocolInstancePanel/ProtocolInstancePanel';
import { ProtocolItem } from 'client/app/apps/protocols/ProtocolInstancePanel/ProtocolItem';
import { useElementOutputs } from 'client/app/apps/protocols/ProtocolInstancePanel/useElementOutputs';
import { useElementNamePopover } from 'client/app/apps/workflow-builder/panels/element-instance-panel/hooks';
import { Parameter } from 'common/types/bundle';

type Props = {
  stepsConfig: StepParamConfigById;
  activeStepId: string;
  elementInstanceId: string;
  elementInstanceName: string;
  outputs: readonly Parameter[];
  onChange: (value: CreateOutputStepState, checked: boolean) => void;
  onClose: () => void;
};

const ProtocolElementOutputInstancePanel = ({
  stepsConfig,
  activeStepId,
  elementInstanceId,
  elementInstanceName,
  outputs: parameters,
  onChange,
  onClose,
}: Props) => {
  const { elementNamePopover, ...elementNamePopoverEvents } =
    useElementNamePopover(elementInstanceName);

  const { outputParameters, hasOutputInActiveStepInAnyElement, handleChange } =
    useElementOutputs(
      elementInstanceId,
      elementInstanceName,
      parameters,
      stepsConfig,
      activeStepId,
      onChange,
    );

  return (
    <ProtocolInstancePanel
      title={elementInstanceName}
      onClose={onClose}
      {...elementNamePopoverEvents}
    >
      <StyledStack>
        {outputParameters.map(o => (
          <ProtocolItem
            key={`${elementInstanceId}-${o.parameter.name}`}
            onToggle={() => handleChange(o.parameter, !o.isEnabled)}
            elementId={elementInstanceId}
            parameter={o.parameter}
            value={undefined}
            checked={o.isEnabled}
            isDisabled={hasOutputInActiveStepInAnyElement && !o.isEnabled}
            otherStepMembership={o.otherStepMembership}
          />
        ))}
      </StyledStack>
      {elementNamePopover}
    </ProtocolInstancePanel>
  );
};

const StyledStack = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(2),
  padding: spacing(0, 2),
}));

export default ProtocolElementOutputInstancePanel;
