import React from 'react';

import { styled } from '@mui/material/styles';

import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import { ProtocolInstancesQuery } from 'client/app/gql';
import { EntityCard } from 'common/ui/components/EntityCard';
import { ExampleWorkflowIcon } from 'common/ui/icons';

type Props = {
  data: ProtocolInstancesQuery;
  handleOnProtocolInstanceClick: (id: string) => void;
};

const ProtocolInstancesList = ({ data, handleOnProtocolInstanceClick }: Props) => {
  const protocolInstances = data.protocolInstances.items || [];

  const latestVersion = Math.max(
    ...protocolInstances.map(i => i.protocol.version),
  ) as ProtocolVersion;
  const showVersion = protocolInstances.some(i => i.protocol.version !== latestVersion);

  return (
    <List>
      {protocolInstances.map(protocolInstance => (
        <EntityCard
          key={protocolInstance.id}
          icon={<ExampleWorkflowIcon />}
          nameColumn={{ label: 'Protocol instance name', value: protocolInstance.name }}
          authorColumn={{
            label: 'Author',
            value: protocolInstance.createdBy.displayName,
          }}
          additionalColumn={
            showVersion
              ? { label: 'Version', value: `${protocolInstance.protocol.version}` }
              : undefined
          }
          dateColumn={{
            label: 'Date',
            value: new Date(protocolInstance.createdAt),
          }}
          interaction={{
            onClick: () => handleOnProtocolInstanceClick(protocolInstance.id),
          }}
        />
      ))}
    </List>
  );
};

const List = styled('div')(({ theme }) => ({
  ...experimentsStyles(theme).list,
}));

export default ProtocolInstancesList;
