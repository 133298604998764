import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { deviceFromGraphQL } from 'client/app/api/deviceFromGraphql';
import { QUERY_ALL_DEVICES } from 'client/app/api/gql/queries';
import DeviceItems from 'client/app/apps/workflow-builder/panels/workflow-settings/devices/DeviceItems';
import DeviceSelectorPanel, {
  DEVICE_SELECTOR_PANEL_ID,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/devices/DeviceSelectorPanel';
import { useWorkflowSettingsState } from 'client/app/apps/workflow-builder/panels/workflow-settings/workflowSettingsState';
import { DeviceIcon } from 'client/app/icons';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import { ConfiguredDevice, ConfiguredDeviceDeviceId } from 'common/types/bundle';
import {
  addAccessibleDevice,
  removeAccessibleDeviceByDeviceId,
} from 'common/types/bundleConfigUtils';
import { Device, SimpleDevice } from 'common/types/device';
import Button from 'common/ui/components/Button';
import { useAdditionalPanelContext } from 'common/ui/providers/AdditionalPanelProvider';

type Props = {
  numStages: number;
};

/**
 * This component is used for showing the selected devices and a button to open the SelectDevicesPanel
 * to allow the user to select the devices.
 */
export default function DeviceSelectorCard({ numStages }: Props) {
  const { allConfiguredDevices, configuredDevicesForSelectedStage, isReadonly } =
    useWorkflowSettingsState();
  const dispatch = useWorkflowBuilderDispatch();
  const { setAdditionalPanel, clearAdditionalPanel, additionalPanelId } =
    useAdditionalPanelContext();

  // We need to fetch all of the devices because the device that we are currently getting from the
  // workflow doesn't have all the info that we need to show the device item (e.g. the image url).
  // Most customers have a small number of devices so it is okay that we are doing a query for all
  // devices.
  const { data, loading } = useQuery(QUERY_ALL_DEVICES);

  // We need to support legacy workflows with 0 stages for readonly display, and in this case, we show all devices.
  const configuredDevices =
    numStages > 0 ? configuredDevicesForSelectedStage : allConfiguredDevices;
  const selectedDevices = useMemo<Device[]>(() => {
    const configuredDeviceIds = configuredDevices.map(cd => cd.deviceId);
    return (
      data?.devices
        .filter(device => configuredDeviceIds.includes(device.id))
        .map(deviceFromGraphQL) ?? []
    );
  }, [configuredDevices, data?.devices]);

  const onSelectedDevicesChange = useCallback(
    (newConfiguredDevices: ConfiguredDevice[]) => {
      dispatch({
        type: 'setSelectedDevices',
        payload: newConfiguredDevices,
      });
    },
    [dispatch],
  );

  const isDeviceSelectorPanelOpen = additionalPanelId === DEVICE_SELECTOR_PANEL_ID;
  const handleToggleDeviceSelectorPanel = useCallback(() => {
    if (isDeviceSelectorPanelOpen) {
      clearAdditionalPanel();
    } else {
      setAdditionalPanel({
        id: DEVICE_SELECTOR_PANEL_ID,
        contents: (
          <DeviceSelectorPanel numStages={numStages} onClose={clearAdditionalPanel} />
        ),
      });
    }
  }, [isDeviceSelectorPanelOpen, setAdditionalPanel, numStages, clearAdditionalPanel]);

  const handleAccessibleDeviceEnabledChange = useCallback(
    (accessibleDevice: SimpleDevice, isEnabled: boolean) => {
      if (!isEnabled) {
        const newConfiguredDevices = removeAccessibleDeviceByDeviceId(
          configuredDevices,
          accessibleDevice.id as ConfiguredDeviceDeviceId,
        );
        onSelectedDevicesChange?.(newConfiguredDevices);
      } else {
        const parentDevice = selectedDevices.find(device =>
          device.accessibleDevices.some(a => a.id === accessibleDevice.id),
        );
        if (!parentDevice) {
          // TODO(CI-1299): Decide if we should be logging this in a better way.
          console.error(
            `Trying to enable an accessible device ${accessibleDevice.id} ` +
              'but there is no corresponding parent device in the workflow config. ' +
              'This can only happen if the workflow config is in a bad state due to a bug.',
          );
          return;
        }
        const newConfiguredDevices = addAccessibleDevice(
          configuredDevices,
          accessibleDevice,
          parentDevice.id,
        );
        onSelectedDevicesChange?.(newConfiguredDevices);
      }
    },
    [configuredDevices, onSelectedDevicesChange, selectedDevices],
  );

  const isEditable =
    !isReadonly && !!onSelectedDevicesChange && !isDeviceSelectorPanelOpen;

  const areDevicesSelected = selectedDevices.length > 0;
  const shouldSelectDevice = !areDevicesSelected || isDeviceSelectorPanelOpen;

  return (
    <Stack spacing={2}>
      {areDevicesSelected && (
        <DeviceItems
          devices={selectedDevices}
          configuredDevices={configuredDevices}
          isEditable={isEditable}
          onAccessibleDeviceEnabledChange={
            (isEditable && handleAccessibleDeviceEnabledChange) || undefined
          }
        />
      )}
      {!isReadonly && (
        <Button
          sx={{ width: '100%' }}
          color="primary"
          variant="tertiary"
          onClick={handleToggleDeviceSelectorPanel}
          size="small"
          startIcon={loading ? <CircularProgress size={18} /> : <DeviceIcon />}
        >
          {`${shouldSelectDevice ? 'Select' : 'Change'} Execution Mode`}
        </Button>
      )}
    </Stack>
  );
}
