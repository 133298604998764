import React, { PropsWithChildren, useEffect, useRef } from 'react';

import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import DisplayName from 'client/app/apps/protocols/annotations/DisplayName';
import EditableDisplayName from 'client/app/apps/protocols/annotations/EditableDisplayName';
import DeleteButton from 'client/app/apps/protocols/StepCard/DeleteButton';
import doNothing from 'common/lib/doNothing';

type Props = {
  name: string;
  onChangeName: (v: string) => void;
  active: boolean;
  /** called when an inactive step is activated */
  onActivate: () => void;
  onDelete?: () => void;
} & PropsWithChildren;

export default function StepCard({
  name,
  onChangeName,
  active,
  onActivate,
  onDelete,
  children,
}: Props) {
  // scroll to the active step when it is first mounted
  const ref = useRef<HTMLDivElement>(null);
  const activeRef = useRef<boolean>(active);
  useEffect(() => {
    if (activeRef.current && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <StyledCard active={active} onClick={active ? doNothing : onActivate} ref={ref}>
      <Stack gap={4}>
        {active ? (
          <Stack direction="row" justifyContent="space-between">
            <EditableDisplayName name={name} onSave={onChangeName} />
            {onDelete ? <DeleteButton onClick={onDelete} /> : null}
          </Stack>
        ) : (
          <DisplayName name={name} />
        )}
        <Collapse in={active}>{children}</Collapse>
      </Stack>
    </StyledCard>
  );
}

const StyledCard = styled(Paper, { shouldForwardProp: prop => prop !== 'active' })<{
  active?: boolean;
}>(({ active, theme: { spacing, palette } }) => ({
  border: active ? `1px solid ${palette.primary.main}` : 'unset',
  cursor: active ? 'unset' : 'pointer',
  borderRadius: '4px',
  padding: spacing(5),
  display: 'flex',
  flexDirection: 'column',
}));
