import React from 'react';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';

import { ExtraPlateDescriptionProps } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/PlateDescriptionEditor';
import PlateSetParameter from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionParameter';
import { isDefined } from 'common/lib/data';
import {
  defaultPlateSetDescription,
  PlateSetDescription,
} from 'common/types/plateSetDescription';
import Button from 'common/ui/components/Button';
import { ArrayItemWrapper } from 'common/ui/components/ParameterEditors/ArrayEditor';
import { useAdditionalPanelContext } from 'common/ui/providers/AdditionalPanelProvider';

type Props = {
  value: PlateSetDescription[] | null;
  onChange: (value: PlateSetDescription[] | null) => void;
  isDisabled: boolean;
  extraProps: ExtraPlateDescriptionProps;
};

/**
 * PlateDescriptionsParameter manages an array of PlateDescriptionParameter that
 * launches the PlateDescriptionEditor in a panel or dialog
 *
 * Dependencies:
 *  - Workflow builder state for querying other parameters in element instance
 *  - AdditionalPanelContext
 */
export default function PlateDescriptionsParameter({
  value,
  onChange,
  extraProps,
  isDisabled,
}: Props) {
  const { clearAdditionalPanel, additionalPanelId: activeId } =
    useAdditionalPanelContext();

  // older workflows (pre-Feb 2025) can sometimes have null entries in the array
  // due to workflow migrations. So we cope with them by filtering here
  const plateDescriptions = value ? value.filter(isDefined) : [];
  const plateNameCount = plateDescriptions.reduce<{ [plateName: string]: number }>(
    (count, { name }) => ({ ...count, [name]: (count?.[name] ?? 0) + 1 }),
    {},
  );

  const handleOnChange = (index: number) => (entry: PlateSetDescription | null) => {
    const update = entry
      ? plateDescriptions.toSpliced(index, 1, entry)
      : plateDescriptions.toSpliced(index, 1);
    onChange(update);
  };

  const handleOnAdd = () => {
    clearAdditionalPanel();
    onChange([...plateDescriptions, defaultPlateSetDescription()]);
  };

  const handleOnDelete = (index: number) => {
    if (activeId === plateDescriptions[index].id) {
      clearAdditionalPanel();
    }
    onChange(plateDescriptions.toSpliced(index, 1));
  };

  return (
    <Stack>
      {plateDescriptions?.map((description, index) => {
        const isDuplicateName = description.name && plateNameCount[description.name] > 1;
        return (
          <ArrayItemWrapper
            key={description.id}
            index={index}
            onClear={() => handleOnDelete(index)}
            isDisabled={isDisabled}
          >
            <PlateSetParameter
              value={description}
              onChange={handleOnChange(index)}
              isDisabled={isDisabled}
              extraProps={extraProps}
              isActive={activeId === description.id}
              warningSlot={
                isDuplicateName ? (
                  <FormHelperText error>Duplicate plate name</FormHelperText>
                ) : undefined
              }
            />
          </ArrayItemWrapper>
        );
      })}
      {!isDisabled ? (
        <Button
          variant="tertiary"
          color="primary"
          onClick={handleOnAdd}
          startIcon={<AddOutlinedIcon />}
        >
          Add plate
        </Button>
      ) : null}
    </Stack>
  );
}
