import React, { useCallback } from 'react';

import DeckOptionsPanel, {
  DECK_OPTIONS_PANEL_ID,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/DeckOptionsPanel';
import Button from 'common/ui/components/Button';
import { DeckOptionsIcon } from 'common/ui/icons';
import { useAdditionalPanelContext } from 'common/ui/providers/AdditionalPanelProvider';

/**
 * This component launches the DeckOptionsPanel.
 */
export default function DeckOptionsParameter() {
  const { setAdditionalPanel, clearAdditionalPanel, additionalPanelId } =
    useAdditionalPanelContext();

  const isDeckOptionsPanelOpen = additionalPanelId === DECK_OPTIONS_PANEL_ID;
  const handleToggleDeckOptionsPanel = useCallback(() => {
    if (isDeckOptionsPanelOpen) {
      clearAdditionalPanel();
    } else {
      setAdditionalPanel({
        id: DECK_OPTIONS_PANEL_ID,
        contents: <DeckOptionsPanel onClose={clearAdditionalPanel} />,
      });
    }
  }, [isDeckOptionsPanelOpen, clearAdditionalPanel, setAdditionalPanel]);

  return (
    <Button
      sx={{ width: '100%' }}
      color="primary"
      variant="tertiary"
      onClick={handleToggleDeckOptionsPanel}
      size="small"
      startIcon={<DeckOptionsIcon />}
    >
      Select Deck Option
    </Button>
  );
}
