import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ProtocolInstancePanel from 'client/app/apps/protocols/ProtocolInstancePanel/ProtocolInstancePanel';
import doNothing from 'common/lib/doNothing';

const StageSelectionPanel = () => {
  return (
    <ProtocolInstancePanel title="Stage devices" onClose={doNothing}>
      <StyledStack>Stage device settings coming soon...</StyledStack>
    </ProtocolInstancePanel>
  );
};

const StyledStack = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(2),
  padding: spacing(0, 2),
}));

export default StageSelectionPanel;
