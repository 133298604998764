import React from 'react';

import DisplayName from 'client/app/apps/protocols/annotations/DisplayName';
import StepContents from 'client/app/apps/protocols/StepCard/StepContents';
import { describeSourceDescription, ProtocolStepOutput } from 'common/types/Protocol';

type Props = {
  outputs: ProtocolStepOutput[];
  onDelete?: (contentIndex: number) => void;
};

export default function OutputStepContents(props: Props) {
  const { outputs, onDelete } = props;

  return (
    <StepContents
      items={outputs}
      getItemKey={({ id }) => id}
      renderItemContents={(output, _) => (
        <DisplayName
          name={output.displayName}
          tooltip={describeSourceDescription(output.sourceDescription)}
        />
      )}
      emptyMessage="Select an output from the elements in the workflow."
      onDelete={onDelete}
    />
  );
}
