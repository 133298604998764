import React, { PropsWithChildren } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import DisplayDescription from 'client/app/apps/protocols/annotations/DisplayDescription';
import DisplayName from 'client/app/apps/protocols/annotations/DisplayName';
import EditableDisplayDescription from 'client/app/apps/protocols/annotations/EditableDisplayDescription';
import EditableDisplayName from 'client/app/apps/protocols/annotations/EditableDisplayName';
import { ProtocolElementParameter } from 'client/app/apps/protocols/ProtocolElementParameter';
import doNothing from 'common/lib/doNothing';
import { Markdown } from 'common/lib/markdown';
import { WorkflowConfig } from 'common/types/bundle';
import { describeSourceDescription, ProtocolStepInput } from 'common/types/Protocol';
import Colors from 'common/ui/Colors';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import { DraggableList } from 'common/ui/components/DragDrop/DraggableList';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type ProtocolStepInputWithValue = ProtocolStepInput & { value: any };

type StepParametersProps = {
  header: JSX.Element;
} & PropsWithChildren;

export const InputStepParameters = ({ header, children }: StepParametersProps) => {
  return (
    <Wrapper>
      {header}
      <ContainerWithIntersectionBar dense noHeader content={children} />
    </Wrapper>
  );
};

export function ParameterSkeletonList() {
  return (
    <Stack spacing={5}>
      <Skeleton sx={{ mb: 3 }} variant="rounded" width="100%" height={20} />
      <Skeleton sx={{ mb: 5 }} variant="rounded" width="100%" height={50} />
      <Skeleton sx={{ mb: 3 }} variant="rounded" width="100%" height={20} />
      <Skeleton variant="rounded" width="100%" height={50} />
    </Stack>
  );
}

type EditorProps = {
  inputParameters: ProtocolStepInputWithValue[];
  workflowConfig: WorkflowConfig;
  updateParameter: (input: ProtocolStepInput, value: any) => void;
  isDisabled: boolean;
};

export function ParameterEditorList({
  inputParameters,
  workflowConfig,
  updateParameter,
  isDisabled,
}: EditorProps) {
  return (
    <Stack spacing={5}>
      {inputParameters.map(inputParameter => {
        const {
          id: inputId,
          displayName,
          displayDescription,
          configuration: editor,
          value,
          elementInstanceId,
        } = inputParameter;
        return (
          <Stack spacing={4} key={inputId}>
            <DisplayName
              name={displayName}
              // don't add sourceDescription as tooltip. The consumer of a
              // protocol does not need this information. Perhaps it will be
              // useful for debugging for a creator, but we have no interactions
              // to enable that yet
              sx={{ fontWeight: 500, fontSize: '14px' }}
            />
            <DisplayDescription description={displayDescription} />
            <ProtocolElementParameter
              elementInstanceId={elementInstanceId}
              editor={editor}
              value={value}
              onChange={newValue => updateParameter(inputParameter, newValue)}
              workflowConfig={workflowConfig}
              isDisabled={isDisabled}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}

type DescriberProps = {
  inputParameters: ProtocolStepInputWithValue[];
  workflowConfig: WorkflowConfig;
  updateDescription: (
    stepInputIndex: number,
    opts: { name?: string; description?: Markdown },
  ) => void;
  updateOrder: (orderedInputIds: string[]) => void;
};

export function ParameterDescriberList({
  inputParameters,
  workflowConfig,
  updateDescription,
  updateOrder,
}: DescriberProps) {
  const handleChangeOrder = (reordered: ProtocolStepInputWithValue[]) => {
    updateOrder(reordered.map(({ id }) => id));
  };

  return (
    <DraggableList
      items={inputParameters}
      getIdFromItem={({ id }) => id}
      onChangeOrder={handleChangeOrder}
      renderItem={(inputParameter, dragProps, index) => {
        const {
          id: inputId,
          displayName,
          displayDescription,
          sourceDescription,
          configuration: editor,
          value,
          elementInstanceId,
        } = inputParameter;
        return (
          <Stack spacing={4} key={inputId}>
            <Stack
              direction="row"
              spacing={4}
              paddingTop={3}
              justifyContent="flex-start"
              alignItems="center"
            >
              {inputParameters.length > 1 && (
                <StyledDragIcon {...dragProps.attributes} {...dragProps.listeners}>
                  {dragProps.dragIcon}
                </StyledDragIcon>
              )}
              <EditableDisplayName
                sx={{ fontWeight: 500, fontSize: '14px' }}
                name={displayName}
                // don't show full description for linked as it become a very long tooltip
                tooltip={
                  inputParameter.linked
                    ? `${inputParameter.linked.length + 1} linked parameters`
                    : describeSourceDescription(sourceDescription)
                }
                onSave={name => updateDescription(index, { name })}
              />
            </Stack>
            <EditableDisplayDescription
              description={displayDescription}
              onSave={value =>
                updateDescription(index, { description: value as Markdown })
              }
            />
            <ProtocolElementParameter
              elementInstanceId={elementInstanceId}
              editor={editor}
              value={value}
              onChange={doNothing}
              workflowConfig={workflowConfig}
              isDisabled
            />
          </Stack>
        );
      }}
    />
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '376px',
  maxWidth: '376px',
  padding: theme.spacing(5),
  gap: theme.spacing(5),
  borderRadius: theme.spacing(3, 0, 0, 3),
  border: `1px solid ${Colors.GREY_30}`,
  backgroundColor: 'white',
}));

const StyledDragIcon = styled('span')(({ theme }) => ({
  '& > svg': {
    color: theme.palette.text.primary,
  },
}));

export const InputStepName = styled(TypographyWithTooltip)(() => ({
  fontWeight: 600,
}));
